import React from "react";
import { Description, Field, FieldGroup, Label } from "./fieldset";
import { Select } from "./select";
import { Input } from "./input";
import { Address, CountryCode } from "@juhuu/sdk-ts";

interface AddressPickerProps {
    setAddress: (address: Address) => void;
    address?: Address;
    disabled?: boolean;
}

const AddressPicker: React.FC<AddressPickerProps> = ({
    setAddress,
    address = { line1: "", line2: "", postalCode: "", city: "", country: "DE" },
    disabled = false,
}) => {
    console.log(address?.country);
    return (
        <>
            <FieldGroup>
                <Field className="flex flex-row justify-between gap-4">
                    <div className="w-1/2">
                        <Label>Address line 1</Label>
                    </div>
                    <div className="w-1/2">
                        <Input
                            defaultValue={address.line1}
                            placeholder="Schrammelgasse 79"
                            onChange={(event) =>
                                setAddress({
                                    ...address,
                                    line1: event.target.value,
                                })
                            }
                        />
                    </div>
                </Field>
                <Field className="flex flex-row justify-between gap-4">
                    <div className="w-1/2">
                        <Label>Address line 2</Label>
                    </div>
                    <div className="w-1/2">
                        <Input
                            defaultValue={address.line2 || ""}
                            onChange={(event) =>
                                setAddress({
                                    ...address,
                                    line2: event.target.value,
                                })
                            }
                        />
                    </div>
                </Field>
                <Field className="flex flex-row justify-between gap-4">
                    <div className="w-1/2">
                        <Label>Postal code</Label>
                    </div>
                    <div className="w-1/2">
                        <Input
                            placeholder="1170"
                            defaultValue={address.postalCode}
                            onChange={(event) =>
                                setAddress({
                                    ...address,
                                    postalCode: event.target.value,
                                })
                            }
                        />
                    </div>
                </Field>
                <Field className="flex flex-row justify-between gap-4">
                    <div className="w-1/2">
                        <Label>City</Label>
                    </div>
                    <div className="w-1/2">
                        <Input
                            placeholder="Wien"
                            defaultValue={address.city}
                            onChange={(event) =>
                                setAddress({
                                    ...address,
                                    city: event.target.value,
                                })
                            }
                        />
                    </div>
                </Field>
                <Field className="flex flex-row justify-between gap-4">
                    <div className="w-1/2">
                        <Label>Country</Label>
                        <Description>
                            If your country does not show up in the list, please
                            contact us.
                        </Description>
                    </div>
                    <div className="w-1/2">
                        <Select
                            defaultValue={address.country}
                            onChange={(event) => {
                                setAddress({
                                    ...address,
                                    country: event.target.value as CountryCode,
                                });
                            }}
                        >
                            <option value={"AT"}>Austria</option>
                            <option value={"DE"}>Germany</option>
                            <option value={"CH"}>Switzerland</option>
                            <option value={"LI"}>Liechtenstein</option>
                            <option value={"IT"}>Italy</option>
                            <option value={"FR"}>France</option>
                            <option value={"NL"}>Netherlands</option>
                            <option value={"BE"}>Belgium</option>
                            <option value={"LU"}>Luxembourg</option>
                            <option value={"DK"}>Denmark</option>
                            <option value={"SE"}>Sweden</option>
                            <option value={"NO"}>Norway</option>
                            <option value={"FI"}>Finland</option>
                            <option value={"IS"}>Iceland</option>
                            <option value={"GB"}>Great Britain</option>
                            <option value={"IE"}>Ireland</option>
                            <option value={"ES"}>Spain</option>
                            <option value={"PT"}>Portugal</option>
                            <option value={"GR"}>Greece</option>
                            <option value={"PL"}>Poland</option>
                            <option value={"CZ"}>Czech Republic</option>
                            <option value={"SK"}>Slovakia</option>
                            <option value={"HU"}>Hungary</option>
                            <option value={"SI"}>Slovenia</option>
                            <option value={"HR"}>Croatia</option>
                            <option value={"BA"}>Bosnia and Herzegovina</option>
                            <option value={"RS"}>Serbia</option>
                            <option value={"US"}>United States</option>
                            <option value={"CA"}>Canada</option>
                        </Select>
                    </div>
                </Field>
            </FieldGroup>
        </>
    );
};

export default AddressPicker;
