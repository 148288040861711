import { Condition, JUHUU } from "@juhuu/sdk-ts";
import { Button } from "../../components/button";
import { useCallback, useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogDescription,
    DialogTitle,
} from "../../components/dialog";

import { Strong, Text } from "../../components/text";
import { Divider } from "../../components/divider";
import jsonLogic from "json-logic-js";
import { Heading, Subheading } from "../../components/heading";
import { useJUHUU } from "../../context/JuhuuContext";

interface DeviceCallToActionButtonProps {
    device: JUHUU.Device.Object | undefined | null;
    deviceTemplate: JUHUU.DeviceTemplate.Object | undefined | null;
}

const DeviceCallToActionButton: React.FC<DeviceCallToActionButtonProps> = ({
    deviceTemplate,
    device: externalDevice,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    // const [mode, setMode] = useState<"regular" | "maintenance">("maintenance");
    const [device, setDevice] = useState<JUHUU.Device.Object | null>(null);
    const juhuu = useJUHUU();
    // device start.use and end.use nodeArray executed flags
    const [
        deviceStartUseNodeArrayExecuted,
        setDeviceStartUseNodeArrayExecuted,
    ] = useState(false);

    const [deviceEndUseNodeArrayExecuted, setDeviceEndUseNodeArrayExecuted] =
        useState(false);

    useEffect(() => {
        if (isOpen === false) {
            return;
        }

        if (externalDevice === null || externalDevice === undefined) {
            return;
        }

        const deviceConnection = juhuu.devices.listen({
            deviceId: externalDevice.id,
        });

        deviceConnection.onUpdated((message) => {
            console.log("device updated", message);
            setDevice(message.payload.after);
        });

        // close websocket connection on unmount
        return () => {
            deviceConnection.close();
        };
    }, [deviceTemplate, externalDevice, isOpen, juhuu]);

    useEffect(() => {
        if (externalDevice === null || externalDevice === undefined) {
            return;
        }

        setDevice(externalDevice);
    }, [externalDevice]);

    async function handleOpenModal() {
        setIsOpen(true);
    }

    async function handleCloseModal() {
        setIsOpen(false);
    }

    const evaluateCondition = useCallback(
        (condition: Condition | null): boolean => {
            if (condition === null) {
                return true;
            }

            if (device === null) {
                return false;
            }

            const data: { [key: string]: boolean | string | number } = {};

            data["status"] = device.status;

            device?.parameterArray.forEach((parameter) => {
                data[parameter.name] = parameter.current;
            });

            const result = jsonLogic.apply(condition.jsonLogic, data);

            if (result === true) {
                return true;
            } else if (result === false) {
                return false;
            } else {
                // asyncAlert(
                //     "Fehler",
                //     "Fehler bei der Auswertung einer Bedingung",
                // );
                return false;
            }
        },
        [device],
    );

    const executeNodeById = useCallback(
        async (nodeId: string): Promise<void> => {
            if (deviceTemplate === null) {
                return;
            }

            if (device === null) {
                return;
            }

            const node = deviceTemplate?.nodeArray.find(
                (node) => node.id === nodeId,
            );

            if (node === undefined) {
                return;
            }

            console.log("executing node", node.id);

            // nodes of type flow.if change the flow of the execution
            let nextNodeIdArray: string[] = [];

            switch (node.type) {
                case "flow.exception": {
                    // await asyncAlert("Fehler", formatLocaleString(node.error));
                    break;
                }

                case "flow.end": {
                    console.log("flow ended");
                    return;
                }

                case "flow.start": {
                    nextNodeIdArray = node.nodeIdArray;
                    break;
                }

                case "flow.if": {
                    // const data: { [key: string]: boolean | string | number } = {};

                    // device?.parameterArray.forEach((parameter) => {
                    //     data[parameter.name] = parameter.current;
                    // });

                    // console.log("data", data);

                    // const result = jsonLogic.apply(node.condition.jsonLogic, data);
                    const result = evaluateCondition(node.condition);

                    if (result === true) {
                        console.log("condition true");
                        nextNodeIdArray = node.trueNodeIdArray;
                    } else {
                        console.log("condition false");
                        nextNodeIdArray = node.falseNodeIdArray;
                    }
                    break;
                }

                case "device.message": {
                    nextNodeIdArray = node.nodeIdArray;
                    await juhuu.devices.message({
                        deviceId: device.id,
                        message: node.message,
                    });

                    break;
                }

                case "property.notify": {
                    nextNodeIdArray = node.nodeIdArray;
                    // await asyncAlert("Fehler", "property.notify not implemented");
                    break;
                }

                case "user.notify": {
                    nextNodeIdArray = node.nodeIdArray;
                    // await asyncAlert("Fehler", "user.notify not implemented");
                    break;
                }

                case "command.execute": {
                    await juhuu.devices.commandExecute({
                        deviceId: device.id,
                        commandName: node.commandName,
                    });
                    nextNodeIdArray = node.nodeIdArray;
                    break;
                }

                case "open.browser": {
                    const returnValue = window.open(node.url.web, "_blank");

                    if (returnValue !== null) {
                        returnValue.focus();
                    }
                    break;
                }
                case "open.phone": {
                    // await openPhone(node.phone);
                    break;
                }

                case "parameter.set": {
                    await juhuu.devices.parameterUpdate({
                        deviceId: device.id,
                        parameterName: node.parameterName,
                        value: node.value,
                    });

                    nextNodeIdArray = node.nodeIdArray;
                    break;
                }

                default: {
                    return;
                }
            }

            if (nextNodeIdArray.length === 0) {
                console.log("nextNodeIdArray empty");
                return;
            } else {
                await Promise.all(
                    nextNodeIdArray.map(async (nodeId) => {
                        await executeNodeById(nodeId);
                    }),
                );
            }
        },
        [device, deviceTemplate, evaluateCondition, juhuu],
    );

    const executeNodeArray = useCallback(
        async ({
            triggerName,
            buttonName,
        }: {
            triggerName: string;
            buttonName?: string;
        }): Promise<void> => {
            if (deviceTemplate === null) {
                return;
            }

            if (deviceTemplate?.nodeArray.length === 0) {
                return;
            }

            console.log("nodeArray", deviceTemplate?.nodeArray);

            // get start of the nodeArray for a specific trigger
            const startNode = deviceTemplate?.nodeArray.find((node) => {
                return (
                    node.type === "flow.start" && node.trigger === triggerName
                );
            });

            if (startNode === undefined) {
                console.log("no trigger found for triggerName", triggerName);
                return;
            }

            if (startNode.type !== "flow.start") {
                return;
            }

            // if the nodeArray was triggered by button.pressed,
            // check if for the pressed button a nodeArray is specified
            if (
                startNode.trigger === "button.pressed" &&
                startNode.buttonName !== buttonName
            ) {
                return;
            }

            console.log(
                "starting nodeArray execution for device and triggerName",
                triggerName,
            );

            await executeNodeById(startNode.id);
        },
        [deviceTemplate, executeNodeById],
    );

    // execute "device.startUse" nodeArray on mount and "device.endUse" on unmount
    useEffect(() => {
        if (
            isOpen === false ||
            device === null ||
            deviceTemplate === null ||
            deviceStartUseNodeArrayExecuted === true
        ) {
            return;
        }

        executeNodeArray({
            triggerName: "device.startUse",
        });

        setDeviceStartUseNodeArrayExecuted(true);

        return () => {
            if (deviceEndUseNodeArrayExecuted === true) {
                return;
            }
            executeNodeArray({
                triggerName: "device.endUse",
            });

            setDeviceEndUseNodeArrayExecuted(true);
        };
    }, [
        device,
        deviceTemplate,
        deviceEndUseNodeArrayExecuted,
        deviceStartUseNodeArrayExecuted,
        executeNodeArray,
        isOpen,
    ]);

    function renderLayoutBlock(
        layoutBlock: JUHUU.DeviceTemplate.Object["userLayoutBlockArray"][0],
        index: number,
    ) {
        if (evaluateCondition(layoutBlock.visibleCondition) === false) {
            return null;
        }

        switch (layoutBlock.type) {
            case "text.heading": {
                return <Heading key={index}>{layoutBlock.text.en}</Heading>;
            }

            case "text.plain": {
                return <Text key={index}>{layoutBlock.text.en}</Text>;
            }

            case "image": {
                return (
                    <div
                        style={{
                            width: "100%",
                            height: layoutBlock.height,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            key={index}
                            src={layoutBlock.urlLight}
                            style={{
                                overflow: "hidden",
                                height: layoutBlock.height,
                            }}
                            alt=""
                        />
                    </div>
                );
            }

            case "text.subtitle": {
                return (
                    <Subheading key={index}>{layoutBlock.text.en}</Subheading>
                );
            }

            case "button.large": {
                return (
                    <Button
                        onClick={() =>
                            executeNodeArray({
                                triggerName: "button.pressed",
                                buttonName: layoutBlock.buttonName,
                            })
                        }
                    >
                        {layoutBlock.text.en}
                    </Button>
                );
            }

            case "button.small": {
                return (
                    <Button
                        onClick={() =>
                            executeNodeArray({
                                triggerName: "button.pressed",
                                buttonName: layoutBlock.buttonName,
                            })
                        }
                    >
                        {layoutBlock.text.en}
                    </Button>
                );
            }

            case "form": {
                // return (
                //   <Form key={index}>
                //     {layoutBlock.columnArray.map(renderFormColumn)}
                //   </Form>
                // );
                break;
            }

            default: {
                return null;
            }
        }
    }

    return (
        <>
            <Button onClick={() => handleOpenModal()}>
                {deviceTemplate?.callToAction.en ?? "Use device"}
            </Button>
            <Dialog open={isOpen} onClose={handleCloseModal}>
                <DialogTitle>
                    {deviceTemplate?.callToAction.en} {device?.name}
                </DialogTitle>
                <DialogDescription>
                    Here you can use your device as you would using the JUHUU
                    app.
                </DialogDescription>
                <DialogBody>
                    <Divider className="mb-4" />
                    {device?.parameterArray.map((parameter) => {
                        return (
                            <div className="flex flex-row justify-start items-center gap-4">
                                <Strong>{parameter.name}</Strong>
                                <Text>{parameter.current}</Text>
                            </div>
                        );
                    })}
                    <Divider className="my-4" />
                    {/* <Navbar>
            <NavbarSection className="max-lg:hidden">
              <NavbarItem
                current={mode === "maintenance"}
                onClick={() => setMode("maintenance")}
              >
                Maintenance
              </NavbarItem>
              <NavbarItem
                current={mode === "regular"}
                onClick={() => setMode("regular")}
              >
                Regular user
              </NavbarItem>
            </NavbarSection>
          </Navbar> */}
                </DialogBody>
                {deviceTemplate?.userLayoutBlockArray.map(renderLayoutBlock)}
                <DialogActions>
                    <Button plain onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button
                        onClick={() =>
                            executeNodeArray({
                                triggerName: "button.pressed",
                                buttonName:
                                    deviceTemplate?.userLayoutBlockActionButton
                                        ?.buttonName,
                            })
                        }
                    >
                        {deviceTemplate?.userLayoutBlockActionButton?.text.en}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

interface DeviceProps {
    deviceTemplate: JUHUU.DeviceTemplate.Object | undefined | null;
    device: JUHUU.Device.Object | undefined | null;
    display: "callToActionButton";
}

const Device: React.FC<DeviceProps> = ({ deviceTemplate, display, device }) => {
    switch (display) {
        case "callToActionButton": {
            return (
                <DeviceCallToActionButton
                    deviceTemplate={deviceTemplate}
                    device={device}
                />
            );
        }

        default: {
            return null;
        }
    }
};

export default Device;
