import { JUHUU } from "@juhuu/sdk-ts";
import { Badge } from "../../components/badge";

interface BadgeStatusPaymentRefundProps {
    status: JUHUU.PaymentRefund.Object["status"] | null | undefined;
}

const BadgeStatusPaymentRefund: React.FC<BadgeStatusPaymentRefundProps> = ({
    status,
}) => {
    switch (status) {
        case "failed": {
            return <Badge color="red">Failed</Badge>;
        }

        case "waitingForArrivalAtUser": {
            return <Badge color="zinc">Waiting for arrival at user</Badge>;
        }

        case "waitingForPayout": {
            return <Badge color="zinc">Waiting for payout</Badge>;
        }

        case "payedOut": {
            return <Badge color="lime">Payed out</Badge>;
        }

        default: {
            return null;
        }
    }
};

export default BadgeStatusPaymentRefund;
