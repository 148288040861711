import { useCallback, useState } from "react";
import { Heading, Subheading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Button } from "../../components/button";
import { Divider } from "../../components/divider";
import {
    Description,
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from "../../components/fieldset";
import { Input } from "../../components/input";
import { useNavigate } from "react-router-dom";
import { ColorScheme } from "@juhuu/sdk-ts";
import { useJUHUU } from "../../context/JuhuuContext";
import ColorPicker from "../../components/color-picker";
import { TextLink } from "../../components/text";
import { Badge } from "../../components/badge";

interface PropertySettingsRouteProps {}

const PropertySettingsRoute: React.FC<PropertySettingsRouteProps> = () => {
    const navigate = useNavigate();
    const { property, setProperty } = useProperty();
    const [propertySaveLoading, setPropertySaveLoading] = useState(false);
    const juhuu = useJUHUU();

    // name
    const [name, setName] = useState<string>("");

    //email
    const [email, setEmail] = useState<string>("");

    //phone
    const [phone, setPhone] = useState<string>("");

    //FaqUrl
    const [faqUrl, setFaqUrl] = useState<string>("");

    //website
    const [website, setWebsite] = useState<string>("");

    //contactUrl
    const [contactUrl, setcontactUrl] = useState<string>("");

    // color scheme
    const [lightPrimary, setLightPrimary] = useState<string>(
        property?.colorScheme.light.primary || "#ffffff",
    );
    const [darkPrimary, setDarkPrimary] = useState<string>(
        property?.colorScheme.dark.primary || "#ffffff",
    );

    const handleUpdate = useCallback(async () => {
        if (property === null) {
            return;
        }

        setPropertySaveLoading(true);
        const query = await juhuu.properties.update({
            propertyId: property.id,
            name: name === "" ? undefined : name,
            email: email === "" ? undefined : email,
            phone: phone === null ? undefined : phone,
            faqUrl: faqUrl === "" ? undefined : faqUrl,
            website: website === "" ? undefined : website,
            contactUrl: contactUrl === "" ? undefined : contactUrl,
            colorScheme: {
                light: {
                    primary: lightPrimary === "" ? undefined : lightPrimary,
                },
                dark: {
                    primary: darkPrimary === "" ? undefined : darkPrimary,
                },
            } as unknown as ColorScheme,
        });
        setPropertySaveLoading(false);

        console.log(darkPrimary);
        console.log(lightPrimary);

        if (query.ok === false) {
            return;
        }

        if (query.data.property.type !== "internal") {
            return;
        }

        setProperty(query.data.property);

        navigate(`/properties/${property?.id}`);
    }, [
        property,
        navigate,
        setProperty,
        name,
        lightPrimary,
        darkPrimary,
        juhuu,
        email,
        phone,
        faqUrl,
        website,
        contactUrl,
    ]);

    return (
        <>
            <Heading>Settings</Heading>
            <Divider className="mb-4" />
            <Fieldset className="mb-8">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Name</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                The name of your company that will be visible to
                                your users.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                defaultValue={property?.name}
                                disabled={propertySaveLoading}
                                placeholder="JUHUU"
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>E-mail</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                It can be used by your users to contact you and
                                will be displayed to everyone using JUHUU.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="email"
                                defaultValue={property?.email ?? ""}
                                disabled={propertySaveLoading}
                                placeholder="office@juhuu.app"
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Phone number</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                It can be used by your users to contact you and
                                will be displayed to everyone using JUHUU.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                defaultValue={property?.phone ?? ""}
                                disabled={propertySaveLoading}
                                placeholder=""
                                onChange={(event) =>
                                    setPhone(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Frequently asked questions</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                Provide a link to your own website or an{" "}
                                <TextLink href="./../articles">
                                    article
                                </TextLink>{" "}
                                where you list frequently asked questions. It
                                will be displayed to everyone using JUHUU.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="url"
                                defaultValue={property?.faqUrl ?? ""}
                                disabled={propertySaveLoading}
                                placeholder=""
                                onChange={(event) =>
                                    setFaqUrl(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Website</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                Provide a url to your website. It will be
                                displayed to everyone using JUHUU.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="url"
                                defaultValue={property?.website ?? ""}
                                disabled={propertySaveLoading}
                                placeholder=""
                                onChange={(event) =>
                                    setWebsite(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Contact Url</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                Provide a link to your own contact form that
                                your users can use to contact you. It will be
                                displayed to everyone using JUHUU.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="url"
                                defaultValue={property?.contactUrl ?? ""}
                                disabled={propertySaveLoading}
                                placeholder=""
                                onChange={(event) =>
                                    setcontactUrl(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>

            <Subheading>Color scheme</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-8">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Primary light mode</Label>
                            <Description>
                                The primary color of your company that our
                                platform shows to your users if their device is
                                set to light mode.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <ColorPicker
                                color={lightPrimary}
                                setColor={setLightPrimary}
                                disabled={propertySaveLoading}
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Primary dark mode</Label>
                            <Description>
                                The primary color of your company that our
                                platform shows to your users if their device is
                                set to dark mode.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <ColorPicker
                                color={darkPrimary}
                                setColor={setDarkPrimary}
                                disabled={propertySaveLoading}
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>

            <Divider className="mb-4" />
            <div className="flex justify-end gap-4">
                <Button onClick={() => navigate(-1)} plain>
                    Cancel
                </Button>
                <Button onClick={handleUpdate}>Save changes</Button>
            </div>
        </>
    );
};

export default PropertySettingsRoute;
