import { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";

import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Badge } from "../../components/badge";
import { Text } from "../../components/text";
import Amount from "../../formatters/Amount";
import BadgeStatusPayment from "./BadgeStatusPayment";
import Timestamp from "../../formatters/Timestamp";
import { useJUHUU } from "../../context/JuhuuContext";
import { ArrowUturnRightIcon } from "@heroicons/react/16/solid";
import BadgeStatusPaymentRefund from "../paymentRefunds/BadgeStatusPaymentRefund";

interface PaymentCellProps {
    payment: JUHUU.Payment.Object;
}

const PaymentCell: React.FC<PaymentCellProps> = ({ payment }) => {
    const date = new Date(payment.createdAt);
    const isRecent = date.getTime() > Date.now() - 1000 * 60 * 60 * 24 * 7;
    const [paymentRefundArray, setPaymentRefundArray] = useState<
        JUHUU.PaymentRefund.Object[]
    >([]);

    const juhuu = useJUHUU();
    const { property } = useProperty();

    const handleRetrievePaymentRefundArray = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }
        const query = await juhuu.paymentRefunds.list({
            paymentId: payment.id,
        });

        if (query.ok === false) {
            return;
        }
        setPaymentRefundArray(query.data);
    }, [property, juhuu, payment]);

    useEffect(() => {
        if (payment.amountRefunded === 0) {
            return;
        }

        handleRetrievePaymentRefundArray();
    }, [handleRetrievePaymentRefundArray, payment]);

    return (
        <>
            <TableRow key={payment.id} href={"./" + payment.id}>
                <TableCell>{payment.number ?? <Badge>Soon</Badge>}</TableCell>
                <TableCell>
                    <Amount
                        amount={
                            payment.amountToPayout ??
                            payment.amountFinal ??
                            payment.amountAuthorized
                        }
                        currencyCode={payment.currencyCode}
                    />
                </TableCell>

                <TableCell>
                    <Timestamp timestamp={payment.createdAt} />
                    {isRecent === true && (
                        <Badge color="lime" className="ml-2">
                            New
                        </Badge>
                    )}
                </TableCell>

                <TableCell>
                    <BadgeStatusPayment status={payment.status} />
                </TableCell>
            </TableRow>
            {paymentRefundArray.length === 0 ? null : (
                <>
                    {paymentRefundArray.map((paymentRefund) => {
                        return (
                            <TableRow key={paymentRefund.id}>
                                <TableCell
                                    className="flex items-center flex-row justify-start gap-4"
                                    dense
                                >
                                    <ArrowUturnRightIcon className="h-4 w-4 inline-block" />
                                    {paymentRefund.number ?? (
                                        <Badge>Soon</Badge>
                                    )}
                                </TableCell>
                                <TableCell dense>
                                    <Amount
                                        amount={
                                            paymentRefund.amountToArriveAtUser
                                        }
                                        currencyCode={payment.currencyCode}
                                    />
                                </TableCell>
                                <TableCell dense>
                                    <Timestamp
                                        timestamp={paymentRefund.createdAt}
                                    />
                                </TableCell>
                                <TableCell dense>
                                    <BadgeStatusPaymentRefund
                                        status={paymentRefund.status}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </>
            )}
        </>
    );
};

interface PaymentListRouteProps {}

const PaymentListRoute: React.FC<PaymentListRouteProps> = () => {
    const [paymentArray, setPaymentArray] = useState<JUHUU.Payment.Object[]>(
        [],
    );
    const juhuu = useJUHUU();
    const { property } = useProperty();
    const handleRefresh = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }
        const query = await juhuu.payments.list({
            propertyId: property?.id,
        });
        if (query.ok === false) {
            return;
        }
        setPaymentArray(query.data);
    }, [property, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <Heading>Payments of your users</Heading>
            <Text className="mb-8">
                This list contains all payments of your users.
            </Text>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Number</TableHeader>
                        <TableHeader>Amount</TableHeader>
                        <TableHeader>Created</TableHeader>
                        <TableHeader>Status</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paymentArray.map((payment) => {
                        return (
                            <PaymentCell payment={payment} key={payment.id} />
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default PaymentListRoute;
export { PaymentCell };
