import { JUHUU } from "@juhuu/sdk-ts";
import { Badge } from "../../components/badge";

interface BadgeStatusPaymentProps {
    status: JUHUU.Payment.Object["status"] | null | undefined;
}

const BadgeStatusPayment: React.FC<BadgeStatusPaymentProps> = ({ status }) => {
    switch (status) {
        case "waitingForConfirmation": {
            return <Badge color="zinc">Waiting for user confirmation</Badge>;
        }

        case "waitingForTransitConfirmation": {
            return <Badge color="zinc">Waiting for transit confirmation</Badge>;
        }

        case "waitingForAmountFinalization": {
            return <Badge color="zinc">Waiting for amount finalization</Badge>;
        }

        case "waitingForCapture": {
            return <Badge color="zinc">Waiting for capture</Badge>;
        }

        case "inTransitToProvider": {
            return <Badge color="zinc">In transit to merchant</Badge>;
        }

        case "captured": {
            return <Badge color="zinc">Waiting for payout</Badge>;
        }

        case "inTransitToProperty": {
            return <Badge color="zinc">In transit to your bank</Badge>;
        }

        case "payedOut": {
            return <Badge color="lime">Payed out</Badge>;
        }

        default: {
            return null;
        }
    }
};

export default BadgeStatusPayment;
