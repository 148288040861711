import { useCallback, useEffect, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { Text } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";
import { Divider } from "../../components/divider";
import { useParams } from "react-router-dom";
import { Button } from "../../components/button";

interface AccountingAreaRouteProps {}

const AccountingAreaRoute: React.FC<AccountingAreaRouteProps> = () => {
    const juhuu = useJUHUU();
    const [accountingArea, setAccountingArea] =
        useState<JUHUU.AccountingArea.Object>();
    const { accountingAreaId } = useParams<{ accountingAreaId: string }>();

    const handleRefresh = useCallback(async () => {
        if (accountingAreaId === undefined) {
            return;
        }

        const query = await juhuu.accountingAreas.retrieve({
            accountingAreaId: accountingAreaId,
        });

        if (query.ok === false) {
            return;
        }

        setAccountingArea(query.data.accountingArea);
    }, [accountingAreaId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
                <div className="flex gap-4 items-center">
                    <Heading>{accountingArea?.name}</Heading>
                </div>

                <div className="flex gap-4 items-center">
                    <Text>{accountingAreaId}</Text>
                    <Button href="./edit">Edit</Button>
                </div>
            </div>
            <Divider />
            <div className="mt-4 ">
                <div className="my-2">
                    <Text>Order Number</Text>
                    <Text>{accountingArea?.orderNumber}</Text>
                </div>
            </div>
        </>
    );
};

export default AccountingAreaRoute;
/*
amountAuthorized: number; // amount of the article that was initially authorized
    amountAuthorizedWithoutServiceFee: number; // amount of the article that was authorized without the service fee
    amountFinal: number | null; // amount that was withdrawn from the user
    amountCaptured: number | null; // amount that was captured from the user
    amountToPayout: number | null;*/
