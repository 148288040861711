import React from "react";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownMenu,
} from "./dropdown";

type Option = {
    title: string;
    value: string;
};

type DropdownPickerProps = {
    options: Option[];
    value: string | null;
    setValue: (value: string) => void;
    title?: string;
};

export default function DropdownPicker({
    options,
    value,
    setValue,
    title = "Select an option",
}: DropdownPickerProps) {
    return (
        <div className="w-full">
            <Dropdown>
                <DropdownButton outline>
                    {value
                        ? options.find((option) => option.value === value)
                              ?.title
                        : title}
                    <ChevronDownIcon />
                </DropdownButton>
                <DropdownMenu>
                    {options.map((option) => (
                        <DropdownItem
                            key={option.value}
                            onClick={() => setValue(option.value)}
                        >
                            {option.title}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}
