import React, { useMemo } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Badge, BadgeProps } from "../components/badge";

interface UtilizationProps {
    location: JUHUU.Location.Object | null;
    visible?: boolean;
}

const Utilization: React.FC<UtilizationProps> = ({
    location,
    visible = true,
}) => {
    const color: BadgeProps["color"] = useMemo(() => {
        const errorColor = "red";
        const neutralColor = "zinc";
        const successColor = "lime";

        if (location === null) {
            return errorColor;
        }

        if (location.disabled === true) {
            return errorColor;
        }

        switch (location.type) {
            case "rentableDevice":
            case "rentableDeviceGroup":
                return location.concurrentSessionIdArray.length >=
                    location.maximumConcurrentSessions * 0.1
                    ? successColor
                    : neutralColor;
            default:
                return neutralColor;
        }
    }, [location]);

    const text: string | null = useMemo(() => {
        if (location === null) {
            return null;
        }

        switch (location.type) {
            case "rentableDevice":
            case "rentableDeviceGroup": {
                break;
            }

            default: {
                return null;
            }
        }

        let text = `${location.concurrentSessionIdArray.length} of ${location.maximumConcurrentSessions}`;

        if (location.disabled === true) {
            text += " • Disabled";
        }

        return text;
    }, [location]);

    if (visible === false) {
        return null;
    }

    if (location === null) {
        return null;
    }

    if (text === null) {
        return null;
    }

    return <Badge color={color}>{text}</Badge>;
};

export default Utilization;
