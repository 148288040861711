import { useCallback, useEffect, useMemo, useState } from "react";
import { Heading, Subheading } from "../../components/heading";
import { Button } from "../../components/button";
import { Divider } from "../../components/divider";
import {
    Description,
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from "../../components/fieldset";
import { Input } from "../../components/input";
import { useNavigate, useParams } from "react-router-dom";
import { Select } from "../../components/select";
import { AutoRenewMode, JUHUU, LocaleString } from "@juhuu/sdk-ts";

import { Switch } from "../../components/switch";
import { Strong, Text } from "../../components/text";
import Duration from "../../formatters/Duration";
import { PlusIcon, TrashIcon } from "@heroicons/react/16/solid";
import { useJUHUU } from "../../context/JuhuuContext";
import LocaleStringPicker from "../../components/localestring-picker";

interface TariffEditRouteProps {}

const TariffEditRoute: React.FC<TariffEditRouteProps> = () => {
    const navigate = useNavigate();
    const [tariffSaveLoading, setTariffSaveLoading] = useState(false);
    const { tariffId } = useParams<{ tariffId: string }>();
    const juhuu = useJUHUU();
    const [tariff, setTariff] = useState<JUHUU.Tariff.Object | null>(null);

    // general
    const [name, setName] = useState<LocaleString | null>(null);
    const [duration, setDuration] = useState<number>(600);
    const [roundToMidnight, setRoundToMidnight] = useState<boolean>(false);
    const [manualTerminationEnabled, setManualTerminationEnabled] =
        useState<boolean>(true);

    // pricing
    const [inter, setInter] = useState<number>(600);
    const [amountArray, setAmountArray] = useState<number[]>([50, 50]);
    const [con, setCon] = useState<number>(0);

    // reminder
    const [reminderEnabled, setReminderEnabled] = useState<boolean>(true);
    const [reminderPercentage, setReminderPercentage] = useState<number>(50);

    // auto renew
    const [autoRenewMode, setAutoRenewMode] = useState<AutoRenewMode>("optOut");
    const [autoRenewManualEnabled, setAutoRenewManualEnabled] =
        useState<boolean>(false);

    const handleUpdate = useCallback(async () => {
        if (tariff === null) {
            return;
        }
        setTariffSaveLoading(true);

        const query = await juhuu.tariffs.update({
            name: name ?? undefined,
            tariffId: tariff.id,
            duration: duration,
            roundToMidnight: roundToMidnight,
            manualTerminationEnabled: manualTerminationEnabled,
            reminderEnabled: reminderEnabled,
            reminderPercentage: reminderPercentage,
            autoRenewMode: autoRenewMode,
            autoRenewManualEnabled: autoRenewManualEnabled,
            amount: amountArray,
            continue: con,
            interval: inter,
        });

        setTariffSaveLoading(false);

        if (query.ok === false) {
            return;
        }

        setTariff(query.data.tariff);
        navigate(`./../../${tariff?.id}`);
    }, [
        tariff,
        navigate,
        duration,
        roundToMidnight,
        manualTerminationEnabled,
        reminderEnabled,
        reminderPercentage,
        autoRenewMode,
        autoRenewManualEnabled,
        amountArray,
        con,
        inter,
        juhuu,
        name,
    ]);

    const handleRefresh = useCallback(async () => {
        if (tariffId === undefined) {
            return;
        }

        const query = await juhuu.tariffs.retrieve({
            tariffId: tariffId,
        });

        if (query.ok === false) {
            return;
        }

        setTariff(query.data.tariff);
        setReminderEnabled(query.data.tariff.reminderEnabled);
        setReminderPercentage(query.data.tariff.reminderPercentage);
        setName(query.data.tariff.name);
        setDuration(query.data.tariff.duration);
        setRoundToMidnight(query.data.tariff.roundToMidnight);
        setManualTerminationEnabled(query.data.tariff.manualTerminationEnabled);
        setAutoRenewMode(query.data.tariff.autoRenewMode);
        setAutoRenewManualEnabled(query.data.tariff.autoRenewManualEnabled);
        setInter(query.data.tariff.interval);
        setAmountArray(query.data.tariff.amount);
        setCon(query.data.tariff.continue);
    }, [tariffId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    const amountFinalizationDuration: number = useMemo((): number => {
        if (tariff === null) {
            return 0;
        }

        const tempTariff = {
            ...tariff,
            amount: amountArray,
            continue: con,
            interval: inter,
            duration: duration,
        };

        const finalizationDuration =
            juhuu.tariffs.getAmountFinalizationDuration(tempTariff);

        return finalizationDuration;
    }, [tariff, amountArray, con, inter, duration, juhuu]);

    return (
        <>
            <Heading>Tariff settings</Heading>
            <Divider className="mb-4" />
            {/* {JSON.stringify(tariff, null, 2)} */}
            {amountFinalizationDuration > 2 * 24 * 60 * 60 && (
                <div className="border p-4 my-4 rounded border-red-500 bg-red-50">
                    <Text className="text-red-500">
                        Due to technical limitations it is not possible to set
                        the current pricing model. Please adjust the pricing or
                        the duration of your tariff so that the price stabilizes
                        after two days. Currently, the price stabilizes after{" "}
                        <Duration
                            seconds={amountFinalizationDuration}
                            display="duration"
                        />
                        .
                    </Text>
                </div>
            )}
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Name</Label>
                            <Description>
                                Set the name of the tariff that is displayed in
                                the JUHUU app. Name the tariff something like
                                "Yearly rent" or "10 min. reservation",
                                depending on where this tariff will be used.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <LocaleStringPicker
                                value={name}
                                setValue={setName}
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Duration</Label>
                            <Description>
                                Set the duration of your rental / reservation in
                                seconds.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={tariffSaveLoading}
                                value={duration}
                                placeholder="600"
                                type="number"
                                onChange={(event) =>
                                    setDuration(
                                        parseFloat(event.target.value ?? 600),
                                    )
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Manual termination</Label>
                            <Description>
                                If enabled, your users are allowed to manually
                                terminate their rental / reservation while it is
                                active.
                            </Description>
                        </div>
                        <div className="w-1/2 flex justify-end">
                            <Switch
                                checked={manualTerminationEnabled}
                                onChange={() =>
                                    setManualTerminationEnabled(
                                        !manualTerminationEnabled,
                                    )
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Round to midnight</Label>
                            <Description>
                                If enabled, the rental / reservation will be
                                rounded to midnight of that day. This results in
                                a slightly longer duration than the one set
                                above.
                            </Description>
                        </div>
                        <div className="w-1/2 flex justify-end">
                            <Switch
                                checked={roundToMidnight}
                                onChange={() =>
                                    setRoundToMidnight(!roundToMidnight)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Pricing</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Step size</Label>
                            <Description>
                                Set the step size in seconds in which the
                                pricing should be calculated. If the step size
                                is set to 15 minutes, the pricing will be
                                calculated in 15 minute steps.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={tariffSaveLoading}
                                value={inter}
                                placeholder="50"
                                type="number"
                                onChange={(event) =>
                                    setInter(
                                        parseFloat(event.target.value ?? 50),
                                    )
                                }
                            />
                        </div>
                    </Field>
                    {amountArray.map((amount, index) => {
                        return (
                            <Field className="flex flex-row justify-between gap-4 w-auto">
                                <div className="w-1/2 flex flex-row justify-start items-center gap-4">
                                    <div className="w-10 flex flex-col justify-center items-center">
                                        <TrashIcon
                                            className="text-red-500 w-4 h-4 cursor-pointer"
                                            onClick={() => {
                                                const newArray = [
                                                    ...amountArray,
                                                ];
                                                newArray.splice(index, 1);
                                                setAmountArray(newArray);
                                            }}
                                        />
                                    </div>
                                    <div className="w-auto">
                                        <Label>
                                            Step {index + 1} (from{" "}
                                            <Duration
                                                seconds={index * inter}
                                                display="duration"
                                            />{" "}
                                            to{" "}
                                            <Duration
                                                seconds={(index + 1) * inter}
                                                display="duration"
                                            />
                                            )
                                        </Label>
                                        <Description>
                                            Set the price for this step that
                                            should be added to the total amount
                                            if the duration of the rental /
                                            reservation falls within or above
                                            this step.
                                        </Description>
                                    </div>
                                </div>
                                <div className="w-1/2">
                                    <Input
                                        disabled={tariffSaveLoading}
                                        value={amountArray[index]}
                                        placeholder="50"
                                        type="number"
                                        onChange={(event) =>
                                            setAmountArray(
                                                amountArray.map((value, i) =>
                                                    i === index
                                                        ? parseFloat(
                                                              event.target
                                                                  .value ?? 50,
                                                          )
                                                        : value,
                                                ),
                                            )
                                        }
                                    />
                                </div>
                            </Field>
                        );
                    })}
                    <Button
                        onClick={() => setAmountArray([...amountArray, 50])}
                    >
                        <PlusIcon />
                        Add step
                    </Button>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Remaining steps</Label>
                            <Description>
                                Here you can set the price for all the remaining
                                steps.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={tariffSaveLoading}
                                value={con}
                                placeholder="50"
                                type="number"
                                onChange={(event) =>
                                    setCon(parseFloat(event.target.value ?? 0))
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>

            <Subheading>Reminder</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Enabled</Label>
                            <Description>
                                If enabled, your users will be reminded some
                                time after they started a rental or reservation
                                that they still have an active one.
                            </Description>
                        </div>
                        <div className="w-1/2 flex justify-end">
                            <Switch
                                checked={reminderEnabled}
                                onChange={() =>
                                    setReminderEnabled(!reminderEnabled)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Percentage</Label>
                            <Description>
                                Set when the reminder should be sent. 0% means
                                right at the start of the rental / reservation
                                and 100% means right at the end. Typical values
                                are 50% or 75%.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={tariffSaveLoading}
                                value={reminderPercentage}
                                placeholder="50"
                                type="number"
                                onChange={(event) =>
                                    setReminderPercentage(
                                        parseFloat(event.target.value ?? 50),
                                    )
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Automatic renewal</Subheading>
            <Text>
                Automatic renewal is a feature that for one thing helps protect
                your users' stored bikes and for the other might{" "}
                <Strong>increase your revenue</Strong>. This works by
                automatically creating a second rental / reservation once the
                previous on expires. Of course, this only works if you adjust
                your terms of service accordingly. If you need help doing so,
                please reach out to us.
            </Text>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Mode</Label>
                            <Description>
                                Select the mode how automatic renewal should
                                behave.
                                <ul>
                                    <li>
                                        <Strong>Opt-Out:</Strong> On creation of
                                        a rental / reservation the user will be
                                        asked if they want their rental /
                                        reservation to be automatically renewed.
                                        The option to do so is selected by
                                        default.{" "}
                                        <Strong>(recommended option)</Strong>
                                    </li>
                                    <li>
                                        <Strong>Opt-In:</Strong> On creation of
                                        a rental / reservation the user will be
                                        asked if they want their rental /
                                        reservation to be automatically renewed.
                                        The option to do so is deselected by
                                        default.
                                    </li>
                                    <li>
                                        <Strong>On:</Strong> On creation of a
                                        rental / reservation the automatic
                                        renewal of it will be enabled.
                                    </li>
                                    <li>
                                        <Strong>Off:</Strong> On creation of a
                                        rental / reservation the automatic
                                        renewal of it will be disabled.
                                    </li>
                                </ul>
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Select
                                onChange={(event) =>
                                    setAutoRenewMode(
                                        event.target.value as AutoRenewMode,
                                    )
                                }
                                value={autoRenewMode}
                            >
                                <option value={"optOut"}>
                                    Opt-Out (recommended)
                                </option>
                                <option value={"optIn"}>Opt-In</option>
                                <option value={"on"}>On</option>
                                <option value={"off"}>Off</option>
                            </Select>
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4 w-full">
                        <div className="w-1/2">
                            <Label>Manual enabled</Label>
                            <Description>
                                Your users are allowed to manually enable or
                                disable automatic renewal for their rental /
                                reservation while it is active.
                            </Description>
                        </div>
                        <div className="w-1/2 flex justify-end">
                            <Switch
                                checked={autoRenewManualEnabled}
                                onChange={() =>
                                    setAutoRenewManualEnabled(
                                        !autoRenewManualEnabled,
                                    )
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>

            <Divider className="mb-4" />
            <div className="flex justify-end gap-4">
                <Button onClick={() => navigate(-1)} plain>
                    Cancel
                </Button>
                <Button onClick={handleUpdate}>Save changes</Button>
            </div>
        </>
    );
};

export default TariffEditRoute;
