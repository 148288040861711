import React from "react";
import { Field, FieldGroup, Label } from "./fieldset";
import { Input } from "./input";
import { Heading } from "./heading";
import Amount from "../formatters/Amount";
import { CurrencyCode } from "@juhuu/sdk-ts";

type AmountPickerProps = {
    setAmount: (value: number) => void;
    amount: number;
    disabled?: boolean;
    currencyCode: CurrencyCode | null | undefined;
};

const AmountPicker: React.FC<AmountPickerProps> = ({
    setAmount,
    amount,
    disabled = false,
    currencyCode,
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (/^\d*(\.\d{0,2})?$/.test(value)) {
            setAmount(Math.round(parseFloat(value) * 100) || 0);
        }
    };

    return (
        <FieldGroup>
            <Field disabled={disabled}>
                <Label>Amount (in cents)</Label>
                <div className="flex flex-row items-center">
                    <div className="w-2/3">
                        <Input
                            disabled={disabled}
                            value={(amount / 100).toString()}
                            placeholder="0.00"
                            type="number"
                            onChange={handleChange}
                            step="0.01"
                        />
                    </div>
                    <Heading className="w-1/3 text-center">
                        <Amount amount={amount} currencyCode={currencyCode} />
                    </Heading>
                </div>
            </Field>
        </FieldGroup>
    );
};

export default AmountPicker;
