import React, { useEffect, useState } from "react";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import { Button } from "../../components/button";
import SessionArray from "./SessionArray";
import { useJUHUU } from "../../context/JuhuuContext";
import { useNavigate } from "react-router-dom";
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogDescription,
    DialogTitle,
} from "../../components/dialog";
import SessionTypePicker from "../../components/sessionTypePicker";
import { JUHUU } from "@juhuu/sdk-ts";
import { Switch, SwitchField } from "../../components/switch";
import { Field, FieldGroup, Label } from "../../components/fieldset";
import TariffPicker from "../../components/tariffPicker";
import UserPicker from "../../components/userPicker";
import LocationPicker from "../../components/locationPicker";

interface SessionListRouteProps {}

const SessionListRoute: React.FC<SessionListRouteProps> = () => {
    const { property } = useProperty();
    const juhuu = useJUHUU();
    const navigate = useNavigate();
    const [sessionCreateDialogOpen, setSessionCreateDialogOpen] =
        useState(false);
    const [autoRenew, setAutoRenew] = useState(false);

    const [sessionType, setSessionType] =
        useState<JUHUU.Session.Object["type"]>("rent");

    // tariff
    const [tariffArray, setTariffArray] = useState<JUHUU.Tariff.Object[]>([]);
    const [tariff, setTariff] = useState<JUHUU.Tariff.Object | null>(null);

    useEffect(() => {
        if (property === null) {
            return;
        }

        juhuu.tariffs
            .list({
                propertyId: property.id,
            })
            .then((query) => {
                if (query.ok === false) {
                    return;
                }

                setTariffArray(query.data);
            });
    }, [juhuu, property]);

    // user
    const [user, setUser] = useState<JUHUU.User.Object | null>(null);
    const [userArray, setUserArray] = useState<JUHUU.User.Object[]>([]);

    useEffect(() => {
        if (property === null) {
            return;
        }

        juhuu.users
            .list({
                propertyId: property.id,
            })
            .then((query) => {
                if (query.ok === false) {
                    return;
                }

                setUserArray(query.data);
            });
    }, [juhuu, property]);

    // location
    const [location, setLocation] = useState<JUHUU.Location.Object | null>(
        null,
    );
    const [locationArray, setLocationArray] = useState<JUHUU.Location.Object[]>(
        [],
    );

    useEffect(() => {
        if (property === null) {
            return;
        }

        juhuu.locations
            .list({
                propertyId: property.id,
            })
            .then((query) => {
                if (query.ok === false) {
                    return;
                }

                setLocationArray(query.data);
            });
    }, [juhuu, property]);

    const handleSessionExport = async () => {
        console.log("Exporting sessions");
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.sessions.export({
            propertyId: property?.id,
            outputType: "csv",
        });

        if (query.ok === false) {
            return;
        }

        window.open(query.data.outputUrl, "_blank");
    };

    const handleSessionCreate = async () => {
        console.log("Creating session");

        if (property === null) {
            console.error("Property is missing.");
            return;
        }

        if (tariff === null) {
            console.error("Tariff is missing.");
            return;
        }

        if (user === null) {
            console.error("User is missing.");
            return;
        }

        if (location === null) {
            console.error("Location is missing.");
            return;
        }

        const query = await juhuu.sessions.create({
            autoRenew: autoRenew,
            isOffSession: true,
            locationId: location.id,
            sessionType: sessionType,
            tariffId: tariff.id,
            userId: user.id,
        });

        if (query.ok === false) {
            return;
        }

        navigate("./" + query.data.session.id);
    };

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4">
                <Heading>Rentals and Reservations of your users</Heading>

                <div className="flex gap-4 flex-row">
                    <Button onClick={handleSessionExport}>Export</Button>
                    <Button onClick={() => setSessionCreateDialogOpen(true)}>
                        Create
                    </Button>
                </div>
            </div>
            <Text className="mb-8">
                Everytime a user completes a rental or reservation it shows up
                in this list.
            </Text>
            <SessionArray
                sessionListParams={{
                    propertyId: property?.id,
                }}
            />

            <Dialog
                open={sessionCreateDialogOpen}
                onClose={setSessionCreateDialogOpen}
            >
                <DialogTitle>Create rental or reservation</DialogTitle>
                <DialogDescription>
                    You can create a rental or reservation on behalf of a user.
                    If the tariff is not free, make sure your user has a payment
                    method setup for offline payments. If you cannot find the
                    user in the list, they need to accept the terms and
                    conditions of the location first.
                </DialogDescription>
                <DialogBody>
                    <FieldGroup>
                        <Field className="flex flex-row justify-between gap-4">
                            <div className="w-1/2">
                                <Label>Type</Label>
                            </div>
                            <div className="w-1/2 flex justify-end">
                                <SessionTypePicker
                                    sessionType={sessionType}
                                    setSessionType={setSessionType}
                                />
                            </div>
                        </Field>
                        <SwitchField>
                            <Label>Auto renew</Label>
                            <Switch
                                onChange={setAutoRenew}
                                checked={autoRenew === true}
                            />
                        </SwitchField>
                        <Field className="flex flex-row justify-between gap-4">
                            <div className="w-1/2">
                                <Label>Tariff</Label>
                            </div>
                            <div className="w-1/2 flex justify-end">
                                <TariffPicker
                                    tariff={tariff}
                                    setTariff={setTariff}
                                    tariffArray={tariffArray}
                                />
                            </div>
                        </Field>
                        <Field className="flex flex-row justify-between gap-4">
                            <div className="w-1/2">
                                <Label>User</Label>
                            </div>
                            <div className="w-1/2 flex justify-end">
                                <UserPicker
                                    user={user}
                                    setUser={setUser}
                                    userArray={userArray}
                                />
                            </div>
                        </Field>
                        <Field className="flex flex-row justify-between gap-4">
                            <div className="w-1/2">
                                <Label>Location</Label>
                            </div>
                            <div className="w-1/2 flex justify-end">
                                <LocationPicker
                                    location={location}
                                    setLocation={setLocation}
                                    locationArray={locationArray}
                                />
                            </div>
                        </Field>
                    </FieldGroup>
                </DialogBody>
                <DialogActions>
                    <Button
                        plain
                        onClick={() => setSessionCreateDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSessionCreate}
                        disabled={
                            location === null ||
                            user === null ||
                            tariff === null
                        }
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SessionListRoute;
