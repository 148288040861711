import { useCallback, useEffect, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading, Subheading } from "../../components/heading";
import { Text } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";
import { Divider } from "../../components/divider";
import { useParams } from "react-router-dom";
import { Button } from "../../components/button";
import {
    DescriptionDetails,
    DescriptionList,
    DescriptionTerm,
} from "../../components/description-list";

interface TermRouteProps {}

const TermRoute: React.FC<TermRouteProps> = () => {
    const juhuu = useJUHUU();
    const [term, setTerm] = useState<JUHUU.Term.Object>();
    const { termId } = useParams<{ termId: string }>();

    const handleRefresh = useCallback(async () => {
        if (termId === undefined) {
            return;
        }

        const query = await juhuu.terms.retrieve({
            termId: termId,
        });

        if (query.ok === false) {
            return;
        }

        setTerm(query.data.term);
    }, [termId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
                <div className="flex gap-4 items-center">
                    <Heading>{term?.name}</Heading>
                </div>

                <div className="flex gap-4 items-center">
                    <Text>{term?.id}</Text>
                    <Button href="./edit">Edit</Button>
                </div>
            </div>
            <Divider />
            <div className="w-full flex flex-row justify-start items-center gap-4 mt-4">
                <Subheading>URLs</Subheading>
            </div>
            <Divider />

            <DescriptionList>
                <DescriptionTerm>DGSVO URL</DescriptionTerm>
                <DescriptionDetails>
                    <Text>{term?.dsgvoUrl}</Text>
                </DescriptionDetails>

                <DescriptionTerm>Privacy URL</DescriptionTerm>
                <DescriptionDetails>
                    <Text>{term?.url}</Text>
                </DescriptionDetails>
            </DescriptionList>
        </>
    );
};

export default TermRoute;
/*
amountAuthorized: number; // amount of the article that was initially authorized
    amountAuthorizedWithoutServiceFee: number; // amount of the article that was authorized without the service fee
    amountFinal: number | null; // amount that was withdrawn from the user
    amountCaptured: number | null; // amount that was captured from the user
    amountToPayout: number | null;*/
