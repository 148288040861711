import { JUHUU, Offer } from "@juhuu/sdk-ts";
import React from "react";
import { TextLink } from "../components/text";
import LicenseTemplate from "../routes/licenseTemplates/LicenseTemplate";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../components/table";
import OfferTime from "./OfferTime";

interface OfferArrayProps {
    offerArray: Offer[] | null | undefined;
    tariffArray: JUHUU.Tariff.Object[] | null | undefined;
    licenseTemplateArray: JUHUU.LicenseTemplate.Object[] | null | undefined;
}

const OfferArray: React.FC<OfferArrayProps> = ({
    offerArray,
    tariffArray,
    licenseTemplateArray,
}) => {
    if (offerArray === null || offerArray === undefined) {
        return null;
    }

    if (tariffArray === null || tariffArray === undefined) {
        return null;
    }

    if (licenseTemplateArray === null || licenseTemplateArray === undefined) {
        return null;
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeader>Tariff</TableHeader>
                    <TableHeader>Licenses</TableHeader>
                    <TableHeader>Opening hours</TableHeader>
                </TableRow>
            </TableHead>
            <TableBody>
                {offerArray.map((offer) => {
                    const tariff = tariffArray.find(
                        (tariff) => tariff.id === offer.tariffId,
                    );
                    return (
                        <TableRow key={offer.tariffId}>
                            <TableCell>
                                <TextLink
                                    href={"./../../tariffs/" + tariff?.id}
                                >
                                    {tariff?.name?.en}
                                </TextLink>
                            </TableCell>
                            <TableCell>
                                <div className="flex flex-col justify-start items-start">
                                    {offer?.licenseTemplateIdArray?.map(
                                        (licenseTemplateId) => {
                                            const licenseTemplate =
                                                licenseTemplateArray.find(
                                                    (licenseTemplate) =>
                                                        licenseTemplate.id ===
                                                        licenseTemplateId,
                                                );

                                            if (licenseTemplate === undefined) {
                                                return (
                                                    <TextLink
                                                        href={
                                                            "./../../licenseTemplates/" +
                                                            licenseTemplateId
                                                        }
                                                    >
                                                        Go to license template
                                                    </TextLink>
                                                );
                                            }

                                            return (
                                                <>
                                                    <LicenseTemplate
                                                        licenseTemplate={
                                                            licenseTemplate
                                                        }
                                                    />
                                                </>
                                            );
                                        },
                                    )}
                                </div>
                            </TableCell>
                            <TableCell>
                                <OfferTime offerTime={offer.offerTime} />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default OfferArray;
