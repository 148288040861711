import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Page from "./Page";
import SessionRoute from "./routes/sessions/SessionRoute";
import SessionListRoute from "./routes/sessions/SessionListRoute";
import PropertyRoute from "./routes/properties/PropertyRoute";
import {
    Dialog,
    DialogActions,
    DialogDescription,
    DialogTitle,
} from "./components/dialog";
import { Button } from "./components/button";
import { useUser } from "./context/UserContext";
import ErrorRoute from "./routes/ErrorRoute";
import PropertyListRoute from "./routes/properties/PropertyListRoute";
import PropertySettingsRoute from "./routes/properties/PropertySettingsRoute";
import PaymentListRoute from "./routes/payments/PaymentListRoute";
import PaymentRoute from "./routes/payments/PaymentRoute";
import LocationListRoute from "./routes/locations/LocationListRoute";
import LocationRoute from "./routes/locations/LocationRoute";
import LocationEditRoute from "./routes/locations/LocationEditRoute";
import PointsListRoute from "./routes/points/PointsListRoute";
import DeviceListRoute from "./routes/devices/DeviceListRoute";
import DeviceTemplateListRoute from "./routes/deviceTemplates/DeviceTemplateListRoute";
import TariffListRoute from "./routes/tariffs/TariffListRoute";
import TermListRoute from "./routes/terms/TermListRoute";
import TermRoute from "./routes/terms/TermRoute";
import ProductListRoute from "./routes/products/ProductListRoute";
import ProductRoute from "./routes/products/ProductRoute";
import PayoutListRoute from "./routes/payouts/PayoutListRoute";
import AccountingAreaListRoute from "./routes/accountingAreas/AccountingAreaListRoute";
import AccountingAreaRoute from "./routes/accountingAreas/AccountingAreaRoute";
import ConnectorListRoute from "./routes/connectors/ConnectorListRoute";
import SimListRoute from "./routes/sims/SimListRoute";
import SimRoute from "./routes/sims/SimRoute";
import ConnectorRoute from "./routes/connectors/ConnectorRoute";
import DeviceRoute from "./routes/devices/DeviceRoute";
import DeviceTemplateRoute from "./routes/deviceTemplates/DeviceTemplateRoute";
import UserListRoute from "./routes/users/UserListRoute";
import UserRoute from "./routes/users/UserRoute";
import TariffRoute from "./routes/tariffs/TariffRoute";
import TariffEditRoute from "./routes/tariffs/TariffEditRoute";
import ScrollToTop from "./routes/ScrollToTop";
import ArticleListRoute from "./routes/articles/ArticleListRoute";
import ArticleRoute from "./routes/articles/ArticleRoute";
import LinkRoute from "./routes/links/LinkRoute";
import LinkListRoute from "./routes/links/LinkListRoute";
import Spinner from "./components/spinner";
import { useEnvironment } from "./context/EnvironmentContext";
import OnboardingRoute from "./routes/onboarding/OnboardingRoute";
import ProductEditRoute from "./routes/products/ProductEditRoute";
import TermEditRoute from "./routes/terms/TermEditRoute";
import DeviceEditRoute from "./routes/devices/DeviceEditRoute";
import ConnectorEditRoute from "./routes/connectors/ConnectorEditRoute";
import AccountingAreaEditRoute from "./routes/accountingAreas/AccountingAreaEditRoute";
import PropertyBillingRoute from "./routes/properties/PropertyBillingRoute";
import StartSubscriptionBanner from "./routes/properties/StartSubscriptionBanner";

function App() {
    function onClose() {
        console.log("close");
    }
    const location = useLocation();
    const { accountSetupStatus } = useUser();
    const { environment } = useEnvironment();

    return (
        <>
            <Dialog
                open={
                    accountSetupStatus !== "yes" &&
                    location.pathname.split("/")[2] !== undefined
                }
                onClose={onClose}
            >
                {accountSetupStatus === "processing" ||
                accountSetupStatus === "yes" ? (
                    <div className="flex justify-center items-center gap-8 flex-col">
                        <DialogTitle>Checking credentials</DialogTitle>
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <DialogTitle>Login or signup</DialogTitle>
                        <DialogDescription>
                            Before continuing to the dashboard, please login or
                            signup with your JUHUU-Account.
                        </DialogDescription>
                        <DialogActions>
                            <Button
                                plain
                                onClick={() =>
                                    window.open(
                                        "https://identity.juhuu.app/support",
                                    )
                                }
                            >
                                Help & Support
                            </Button>
                            <Button
                                onClick={() => {
                                    if (environment === "development") {
                                        window.location.href =
                                            "https://identity.juhuu.dev/auth?refURL=https://dashboard.juhuu.dev";
                                    } else {
                                        window.location.href =
                                            "https://identity.juhuu.app/auth?refURL=https://dashboard.juhuu.app";
                                    }
                                }}
                            >
                                Login or signup
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            <Page>
                <ScrollToTop />
                <StartSubscriptionBanner />
                <Routes>
                    <Route path="/" element={<OnboardingRoute />} />
                    <Route path="*" element={<ErrorRoute />} />
                    <Route path="properties" element={<PropertyListRoute />} />
                    <Route
                        path="properties/:propertyId"
                        element={<PropertyRoute />}
                    />
                    <Route
                        path="properties/:propertyId/settings"
                        element={<PropertySettingsRoute />}
                    />
                    <Route
                        path="properties/:propertyId/billing"
                        element={<PropertyBillingRoute />}
                    />
                    <Route
                        path="properties/:propertyId/articles"
                        element={<ArticleListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/articles/:articleId"
                        element={<ArticleRoute />}
                    />
                    <Route
                        path="properties/:propertyId/users"
                        element={<UserListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/users/:userId"
                        element={<UserRoute />}
                    />
                    <Route
                        path="properties/:propertyId/sessions"
                        element={<SessionListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/sessions/:sessionId"
                        element={<SessionRoute />}
                    />
                    <Route
                        path="properties/:propertyId/payments"
                        element={<PaymentListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/payments/:paymentId"
                        element={<PaymentRoute />}
                    />
                    <Route
                        path="properties/:propertyId/locations"
                        element={<LocationListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/locations/:locationId"
                        element={<LocationRoute />}
                    />
                    <Route
                        path="properties/:propertyId/locations/:locationId/edit"
                        element={<LocationEditRoute />}
                    />
                    <Route
                        path="properties/:propertyId/points"
                        element={<PointsListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/devices"
                        element={<DeviceListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/devices/:deviceId"
                        element={<DeviceRoute />}
                    />
                    <Route
                        path="properties/:propertyId/devices/:deviceId/edit"
                        element={<DeviceEditRoute />}
                    />
                    <Route
                        path="properties/:propertyId/devices/:deviceId/edit"
                        element={<DeviceEditRoute />}
                    />
                    <Route
                        path="properties/:propertyId/deviceTemplates"
                        element={<DeviceTemplateListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/deviceTemplates/:deviceTemplateId"
                        element={<DeviceTemplateRoute />}
                    />
                    <Route
                        path="properties/:propertyId/tariffs"
                        element={<TariffListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/tariffs/:tariffId"
                        element={<TariffRoute />}
                    />
                    <Route
                        path="properties/:propertyId/tariffs/:tariffId/edit"
                        element={<TariffEditRoute />}
                    />
                    <Route
                        path="properties/:propertyId/links"
                        element={<LinkListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/links/:linkId"
                        element={<LinkRoute />}
                    />
                    <Route
                        path="properties/:propertyId/terms"
                        element={<TermListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/terms/:termId"
                        element={<TermRoute />}
                    />
                    <Route
                        path="properties/:propertyId/terms/:termId/edit"
                        element={<TermEditRoute />}
                    />
                    <Route
                        path="properties/:propertyId/products"
                        element={<ProductListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/products/:productId"
                        element={<ProductRoute />}
                    />
                    <Route
                        path="properties/:propertyId/products/:productId/edit"
                        element={<ProductEditRoute />}
                    />
                    <Route
                        path="properties/:propertyId/payouts"
                        element={<PayoutListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/accountingAreas"
                        element={<AccountingAreaListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/accountingAreas/:accountingAreaId"
                        element={<AccountingAreaRoute />}
                    />
                    <Route
                        path="properties/:propertyId/accountingAreas/:accountingAreaId/edit"
                        element={<AccountingAreaEditRoute />}
                    />
                    <Route
                        path="properties/:propertyId/connectors"
                        element={<ConnectorListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/connectors/:connectorId"
                        element={<ConnectorRoute />}
                    />
                    <Route
                        path="properties/:propertyId/connectors/:connectorId/edit"
                        element={<ConnectorEditRoute />}
                    />
                    <Route
                        path="properties/:propertyId/sims"
                        element={<SimListRoute />}
                    />
                    <Route
                        path="properties/:propertyId/sims/:simId"
                        element={<SimRoute />}
                    />
                </Routes>
                {/* <RouterProvider router={router} /> */}
            </Page>
        </>
    );
}

export default App;
