import React, { useCallback, useEffect, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import ChatArray from "../routes/chats/ChatArray";
import { useUser } from "../context/UserContext";
import { useJUHUU } from "../context/JuhuuContext";
import { useBuddy } from "../context/BuddyContext";
import {
    ArrowLeftCircleIcon,
    PaperAirplaneIcon,
} from "@heroicons/react/16/solid";
import { Heading } from "./heading";
import { Input } from "./input";
import { Button } from "./button";
import ChatMessage from "../routes/chatMessages/ChatMessage";
import { useProperty } from "../context/PropertyContext";

const Buddy: React.FC = () => {
    const { user } = useUser();
    const { property } = useProperty();
    const juhuu = useJUHUU();

    const {
        selectedChat,
        setSelectedChat,
        chatMessages = [],
        setChatMessages,
        fetchMessages,
    } = useBuddy();

    const [message, setMessage] = useState<string>("");

    useEffect(() => {
        if (selectedChat) {
            fetchMessages(selectedChat.id);
        }
    }, [selectedChat, fetchMessages]);

    const handleCreateChat = async () => {
        if (!user) return;

        const query = await juhuu.chats.create({ userId: user.id });

        if (query.ok) {
            setSelectedChat(query.data.chat);
        }
    };

    const handleCreateMessage = useCallback(async () => {
        if (!selectedChat || !user) return;

        const messageCreateQuery = await juhuu.chatMessages.create({
            chatId: selectedChat.id,
            message: message,
            userId: user.id,
            context: {
                ownerPropertyId: property?.id,
                userGroup: user.group,
                userName: user.name ?? undefined,
                frontend: "dashboard",
                platform: "web",
            },
        });

        if (messageCreateQuery.ok) {
            setChatMessages([
                ...chatMessages,
                messageCreateQuery.data.chatMessage,
            ]);
            setMessage("");

            const completeChatQuery = await juhuu.chats.complete({
                chatId: selectedChat.id,
            });

            if (completeChatQuery.ok) {
                setSelectedChat(completeChatQuery.data.chat);
                setChatMessages(
                    completeChatQuery.data.chatMessageArray.reverse(),
                );
            }
        }
    }, [
        chatMessages,
        juhuu,
        message,
        selectedChat,
        user,
        property,
        setChatMessages,
        setSelectedChat,
    ]);

    return (
        <div className="h-screen flex flex-col overflow-hidden p-2 pl-0">
            {selectedChat !== null && (
                <div className="flex flex-row justify-start items-start gap-2 mb-2">
                    <ArrowLeftCircleIcon
                        className="w-6 h-6 text-zinc-300 cursor-pointer"
                        onClick={() => {
                            setSelectedChat(null);
                            setChatMessages([]);
                        }}
                    />
                    <Heading>{selectedChat?.title ?? "New chat"}</Heading>
                </div>
            )}

            <div className="flex flex-grow flex-col justify-start overflow-scroll rounded-xl">
                {selectedChat === null ? (
                    <ChatArray
                        onSelectedChat={setSelectedChat}
                        chatListParams={{ userId: user?.id }}
                    />
                ) : (
                    (chatMessages ?? []).map((chatMessage) => (
                        <ChatMessage
                            key={chatMessage.id}
                            chatMessage={chatMessage}
                            setChatMessage={(updatedMessage) => {
                                setChatMessages((prevMessages) =>
                                    prevMessages.map((msg) =>
                                        msg.id === updatedMessage.id
                                            ? updatedMessage
                                            : msg,
                                    ),
                                );
                            }}
                        />
                    ))
                )}
            </div>

            {selectedChat === null ? (
                <Button onClick={handleCreateChat} className="w-full">
                    Create new chat
                </Button>
            ) : (
                <div className="flex items-center w-full gap-4 mt-4">
                    <Input
                        placeholder="Ask JUHUU Buddy anything..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <PaperAirplaneIcon
                        className="h-6 cursor-pointer"
                        onClick={handleCreateMessage}
                    />
                </div>
            )}
        </div>
    );
};

export default Buddy;
