import { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";

interface PayoutListRouteProps {}

const PayoutListRoute: React.FC<PayoutListRouteProps> = () => {
    const [payoutArray, setPayoutArray] = useState<JUHUU.Payout.Object[]>([]);
    const juhuu = useJUHUU();
    const { property } = useProperty();
    const handleRefresh = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.payouts.list({
            propertyId: property?.id,
        });

        if (query.ok === false) {
            return;
        }

        setPayoutArray(query.data);
    }, [property, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <Heading>Payouts</Heading>
            <Text className="mb-8">
                This list contains the payouts that have been created. Create a
                payout to send the payments you received through JUHUU to your
                bank account. You can group your payments by setting up
                accounting areas. Setup your bank account in settings.
            </Text>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Name</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {payoutArray.map((payout) => {
                        return (
                            <TableRow key={payout.id}>
                                <TableCell>{payout.id} </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default PayoutListRoute;
