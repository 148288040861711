import React from "react";
import { Description, Field, FieldGroup, Fieldset, Label } from "./fieldset";

interface FormLayoutProps {
    label?: string;
    description?: string;
    children: React.ReactNode; // Accept children as a prop
}

const FormLayout: React.FC<FormLayoutProps> = ({
    label = "",
    description = "",
    children, // Destructure children
}) => {
    return (
        <Fieldset className="mb-12">
            <FieldGroup>
                <Field className="flex flex-row justify-between gap-4">
                    <div className="w-1/2">
                        <Label>{label}</Label>
                        <Description>{description}</Description>
                    </div>
                    <div className="w-1/2">
                        {children} {/* Render children here */}
                    </div>
                </Field>
            </FieldGroup>
        </Fieldset>
    );
};

export default FormLayout;
