import { useCallback, useEffect, useState } from "react";
import { Heading, Subheading } from "../../components/heading";
import { Button } from "../../components/button";
import { Divider } from "../../components/divider";
import {
    Description,
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from "../../components/fieldset";
import { Input } from "../../components/input";
import { useNavigate, useParams } from "react-router-dom";
import { JUHUU } from "@juhuu/sdk-ts";
import { useJUHUU } from "../../context/JuhuuContext";

interface AccountingAreaEditRouteProps {}

const AccountingAreaEditRoute: React.FC<AccountingAreaEditRouteProps> = () => {
    const navigate = useNavigate();
    const [accountingAreaSaveLoading, setAccountingAreaSaveLoading] =
        useState(false);
    const { accountingAreaId } = useParams<{ accountingAreaId: string }>();
    const juhuu = useJUHUU();
    const [accountingArea, setAccountingArea] =
        useState<JUHUU.AccountingArea.Object | null>(null);
    const [name, setName] = useState<string>(
        accountingArea ? accountingArea.name : "",
    );
    const [creditPostingRowDescription, setCreditPostingRowDescription] =
        useState<string | undefined>(undefined);
    const [orderNumber, setOrderNumber] = useState<string | undefined>(
        undefined,
    );

    const handleUpdate = useCallback(async () => {
        if (accountingArea === null) {
            return;
        }
        setAccountingAreaSaveLoading(true);

        //TODO:

        const query = await juhuu.accountingAreas.update({
            accountingAreaId: accountingArea.id,
            name: name,
            creditPostingRowDescription: creditPostingRowDescription,
            orderNumber: orderNumber,
        });

        setAccountingAreaSaveLoading(false);

        if (query.ok === false) {
            return;
        }

        setAccountingArea(query.data.accountingArea);
        navigate(`./../../${accountingArea?.id}`);
    }, [
        accountingArea,
        navigate,
        juhuu,
        name,
        creditPostingRowDescription,
        orderNumber,
    ]);

    const handleRefresh = useCallback(async () => {
        if (accountingAreaId === undefined) {
            return;
        }

        const query = await juhuu.accountingAreas.retrieve({
            accountingAreaId: accountingAreaId,
        });

        if (query.ok === false) {
            return;
        }

        setAccountingArea(query.data.accountingArea);
        setName(query.data.accountingArea.name);
        setCreditPostingRowDescription(
            query.data.accountingArea.creditPostingRowDescription,
        );
        setOrderNumber(query.data.accountingArea.orderNumber);
    }, [accountingAreaId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <Heading>Accounting Area settings</Heading>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Device Name</Label>
                            <Description>
                                Change the name of your product
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={accountingAreaSaveLoading}
                                value={name}
                                placeholder="My Accounting Area Name"
                                type="text"
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Credit Posting Row</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Description</Label>
                            <Description>
                                Change the description of your credit posting
                                row
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={accountingAreaSaveLoading}
                                value={creditPostingRowDescription}
                                placeholder="My credit posting row descritpion"
                                type="text"
                                onChange={(event) =>
                                    setCreditPostingRowDescription(
                                        event.target.value,
                                    )
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Order</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Order Number</Label>
                            <Description>
                                Change the order number of your product
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={accountingAreaSaveLoading}
                                value={orderNumber}
                                placeholder="my order number"
                                type="text"
                                onChange={(event) =>
                                    setOrderNumber(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Divider className="mb-4" />
            <div className="flex justify-end gap-4">
                <Button onClick={() => navigate(-1)} plain>
                    Cancel
                </Button>
                <Button onClick={handleUpdate}>Save changes</Button>
            </div>
        </>
    );
};

export default AccountingAreaEditRoute;
