import { useCallback, useEffect, useMemo, useState } from "react";
import { Heading, Subheading } from "../../components/heading";
import { Button } from "../../components/button";
import { Divider } from "../../components/divider";
import {
    Description,
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from "../../components/fieldset";
import { Input } from "../../components/input";
import { useNavigate, useParams } from "react-router-dom";
import { Select } from "../../components/select";
import { AutoRenewMode, JUHUU } from "@juhuu/sdk-ts";

import { Switch } from "../../components/switch";
import { Strong, Text } from "../../components/text";
import Duration from "../../formatters/Duration";
import { PlusIcon, TrashIcon } from "@heroicons/react/16/solid";
import { useJUHUU } from "../../context/JuhuuContext";

interface TermEditRouteProps {}

const TermEditRoute: React.FC<TermEditRouteProps> = () => {
    const navigate = useNavigate();
    const [termSaveLoading, setTermSaveLoading] = useState(false);
    const { termId } = useParams<{ termId: string }>();
    const juhuu = useJUHUU();
    const [term, setTerm] = useState<JUHUU.Term.Object | null>(null);

    const [name, setName] = useState<string>(term ? term.name : "");
    const [url, setUrl] = useState<string>(term ? term.url : "");
    const [dsgvoUrl, setDsgvoUrl] = useState<string>(term ? term.dsgvoUrl : "");

    const handleUpdate = useCallback(async () => {
        if (term === null) {
            return;
        }
        setTermSaveLoading(true);

        const query = await juhuu.terms.update({
            termId: term.id,
            name: name,
            url: url,
            dsgvoUrl: dsgvoUrl,
        });

        setTermSaveLoading(false);

        if (query.ok === false) {
            return;
        }

        setTerm(query.data.term);
        navigate(`./../../${term?.id}`);
    }, [term, navigate, juhuu, name, url, dsgvoUrl]);

    const handleRefresh = useCallback(async () => {
        if (termId === undefined) {
            return;
        }

        const query = await juhuu.terms.retrieve({
            termId: termId,
        });

        if (query.ok === false) {
            return;
        }

        setTerm(query.data.term);
        setName(query.data.term.name);
        setUrl(query.data.term.url);
        setDsgvoUrl(query.data.term.dsgvoUrl);
    }, [termId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <Heading>Term settings</Heading>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Term Name</Label>
                            <Description>
                                Change the Name of your term
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={termSaveLoading}
                                value={name}
                                placeholder="My Term Name"
                                type="text"
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Divider className="mb-4" />
            <Subheading>URLs</Subheading>
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Url</Label>
                            <Description>
                                Change the Url of your term
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={termSaveLoading}
                                value={url}
                                placeholder="My Term Url"
                                type="text"
                                onChange={(event) => setUrl(event.target.value)}
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>DSGVO Url</Label>
                            <Description>
                                Change the DSGVO Url of your term
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={termSaveLoading}
                                value={dsgvoUrl}
                                placeholder="My Term DSGVO Url"
                                type="text"
                                onChange={(event) =>
                                    setDsgvoUrl(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>

            <Divider className="mb-4" />
            <div className="flex justify-end gap-4">
                <Button onClick={() => navigate(-1)} plain>
                    Cancel
                </Button>
                <Button onClick={handleUpdate}>Save changes</Button>
            </div>
        </>
    );
};

export default TermEditRoute;
