import { JUHUU } from "@juhuu/sdk-ts";

interface LocationTypeProps {
    type: JUHUU.Location.Object["type"] | null | undefined;
}

const LocationType: React.FC<LocationTypeProps> = ({ type }) => {
    switch (type) {
        case "rentableDevice": {
            return <>Rentable Single Location</>;
        }

        case "rentableDeviceGroup": {
            return <>Rentable Group Location</>;
        }

        case "useableDevice": {
            return <>Useable Single Location</>;
        }

        default: {
            return null;
        }
    }
};

export default LocationType;
