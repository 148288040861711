import React, { useCallback, useState } from "react";
import AnimatedGradient from "./animated-gradient";
import Container from "../../components/container";
import { useUser } from "../../context/UserContext";
import Spinner from "../../components/spinner";
import { useEnvironment } from "../../context/EnvironmentContext";
import PropertyArrayComponent from "../properties/PropertyArray";
import {
    Description,
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from "../../components/fieldset";
import { Input } from "../../components/input";
import { Button } from "../../components/button";
import { useNavigate } from "react-router-dom";
import { useJUHUU } from "../../context/JuhuuContext";
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogDescription,
    DialogTitle,
} from "../../components/dialog";
import ColorPicker from "../../components/color-picker";
import ImagePicker from "../../components/image-picker";
import { Divider } from "../../components/divider";
import { Text, TextLink } from "../../components/text";
import {
    Checkbox,
    CheckboxField,
    CheckboxGroup,
} from "../../components/checkbox";

interface OnboardingRouteProps {}

const OnboardingRoute: React.FC<OnboardingRouteProps> = () => {
    const { accountSetupStatus } = useUser();
    const { environment } = useEnvironment();
    const [name, setPropertyName] = useState("");
    const [propertyOnboardLoading, setPropertyOnboardLoading] = useState(false);
    const [enableStartButton, setEnableStartButton] = useState(false);
    const navigate = useNavigate();
    const juhuu = useJUHUU();
    const [propertyCreateDialog, setPropertyCreateDialog] = useState(false);
    const [checkboxAllowedToUpload, setCheckboxAllowedToUpload] =
        useState(false);
    const [checkboxTerms, setCheckboxTerms] = useState(false);
    const [primaryColor, setPrimaryColor] = useState<string>("#6e17fa");
    const [backgroundColor, setBackgroundColor] = useState<string>("#baa6d3");
    const [logo, setLogo] = useState<string | null>(null);
    const { user, logout } = useUser();

    const handleOnboardProperty = useCallback(async () => {
        if (
            primaryColor === null ||
            backgroundColor === null ||
            logo === null
        ) {
            return;
        }

        console.log("Updating property");
        setPropertyOnboardLoading(true);
        const query = await juhuu.properties.onboarding({
            logo: logo ?? "",
            primaryColor: primaryColor,
            backgroundColor: backgroundColor,
            name: name,
        });
        setPropertyOnboardLoading(false);

        if (query.ok === false) {
            return;
        }

        navigate(`/properties/${query.data.property?.id}`);
        setPropertyCreateDialog(false);
    }, [primaryColor, backgroundColor, juhuu, navigate, logo, name]);

    return (
        <>
            <AnimatedGradient
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 0,
                }}
                // clipPath="polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
                clipPath="polygon(0% 0%, 100% 0%, 100% 70%, 0% 100%)"
            />
            <div
                style={{
                    position: "relative",
                    zIndex: 1,
                    width: "100vw",
                    height: "60vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <Container>
                    <p className="text-7xl font-bold text-center">
                        Build your Business now.
                    </p>
                    <p className="text-3xl font-bold text-center text-white mt-4 mb-6">
                        Up and running in 5 minutes.
                    </p>
                    {accountSetupStatus === "no" && (
                        <div className="flex flex-row justify-center gap-4">
                            <div
                                className="cursor-pointer bg-white rounded-full flex items-center justify-center py-4 px-6 shadow-xl"
                                onClick={() => {
                                    if (environment === "development") {
                                        window.location.href =
                                            "https://identity.juhuu.dev/auth?refURL=https://dashboard.juhuu.dev";
                                    } else {
                                        window.location.href =
                                            "https://identity.juhuu.app/auth?refURL=https://dashboard.juhuu.app";
                                    }
                                }}
                            >
                                <p className="text-xl text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-green-500 font-bold">
                                    Login or signup
                                </p>
                            </div>
                        </div>
                    )}
                    {accountSetupStatus === "processing" && (
                        <div className="flex flex-row justify-center w-full">
                            <Spinner />
                        </div>
                    )}
                    {accountSetupStatus === "yes" && (
                        <div className="flex flex-row justify-center w-full gap-4">
                            {propertyOnboardLoading === true ? (
                                <div className="flex flex-row gap-4 items-center justify-center">
                                    <Text>Creating {name}</Text>
                                    <Spinner />
                                </div>
                            ) : (
                                <>
                                    <div className="w-80">
                                        <Field>
                                            <Input
                                                name="name"
                                                placeholder="Name of your business"
                                                onChange={(event) => {
                                                    if (
                                                        event.target.value
                                                            .length < 4 ||
                                                        event.target.value
                                                            .length > 100
                                                    ) {
                                                        setEnableStartButton(
                                                            false,
                                                        );
                                                    } else {
                                                        setEnableStartButton(
                                                            true,
                                                        );
                                                    }
                                                    setPropertyName(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        </Field>
                                    </div>
                                    <Button
                                        disabledTooltip="Please enter the name of your business."
                                        onClick={() =>
                                            setPropertyCreateDialog(true)
                                        }
                                        disabled={enableStartButton === false}
                                    >
                                        Start
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                </Container>
            </div>
            {user === null ? null : (
                <Container className="mb-16">
                    <PropertyArrayComponent
                        propertyListParams={{
                            userId: user.id,
                        }}
                    />
                </Container>
            )}
            <Container className="mb-16 min-h-96">
                <PropertyArrayComponent propertyListParams={{}} />
            </Container>
            <Dialog
                open={propertyCreateDialog}
                onClose={setPropertyCreateDialog}
            >
                <DialogTitle>Customize your appearance</DialogTitle>
                <DialogDescription>
                    To get your business listed on JUHUU, please customize it to
                    your brand.
                </DialogDescription>
                <DialogBody>
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <Label>Select your primary color</Label>
                                <ColorPicker
                                    color={primaryColor}
                                    setColor={setPrimaryColor}
                                    disabled={propertyOnboardLoading}
                                />
                            </Field>
                            <Field>
                                <Label>Select your background color</Label>
                                <ColorPicker
                                    color={backgroundColor}
                                    setColor={setBackgroundColor}
                                    disabled={propertyOnboardLoading}
                                />
                            </Field>
                            <Field>
                                <Label>Upload your logo</Label>
                                <ImagePicker
                                    image={logo}
                                    setImage={setLogo}
                                    disabled={propertyOnboardLoading}
                                />
                            </Field>
                            <CheckboxGroup>
                                <CheckboxField>
                                    <Checkbox
                                        name="discoverability"
                                        value="show_on_events_page"
                                        defaultChecked={false}
                                        onChange={(checked) => {
                                            setCheckboxTerms(checked);
                                        }}
                                    />
                                    <Label>Accept terms and conditions</Label>
                                    <Description>
                                        Before checking this box please read our{" "}
                                        <TextLink
                                            href="https://docs.juhuu.app/legal/terms-for-dashboard"
                                            target="_blank"
                                        >
                                            terms and conditions
                                        </TextLink>
                                        .
                                    </Description>
                                </CheckboxField>
                                <CheckboxField>
                                    <Checkbox
                                        name="discoverability"
                                        value="show_on_events_page"
                                        defaultChecked={false}
                                        onChange={(checked) => {
                                            setCheckboxAllowedToUpload(checked);
                                        }}
                                    />
                                    <Label>Allowed to upload</Label>
                                    <Description>
                                        Check this box if you are allowed to
                                        upload this information on behalf of
                                        this business. JUHUU displays this
                                        information to the public.
                                    </Description>
                                </CheckboxField>
                            </CheckboxGroup>
                        </FieldGroup>
                    </Fieldset>
                </DialogBody>
                <Divider className="mt-8" />
                <DialogActions>
                    {propertyOnboardLoading === true ? (
                        <>
                            <Text>Uploading image...</Text>
                            <Spinner />
                        </>
                    ) : (
                        <>
                            <Button
                                plain
                                href="https://juhuu.app/contact"
                                target="_blank"
                            >
                                Contact sales
                            </Button>
                            <Button
                                onClick={handleOnboardProperty}
                                disabledTooltip={
                                    "Select a logo, a primary color and a background color."
                                }
                                disabled={
                                    logo === null ||
                                    primaryColor === null ||
                                    backgroundColor === null ||
                                    checkboxTerms === false ||
                                    checkboxAllowedToUpload === false
                                }
                            >
                                Create business {name}
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
            <div className="flex flex-row justify-start gap-4 px-4 pb-4 pt-32 w-full">
                <TextLink href="https://docs.juhuu.app/legal/impressum">
                    Imprint
                </TextLink>
                <TextLink href="https://juhuu.app/contact">Contact</TextLink>
                {user !== null && (
                    <TextLink href="/" onClick={() => logout()}>
                        Logout
                    </TextLink>
                )}
            </div>
        </>
    );
};

export default OnboardingRoute;
