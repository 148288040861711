import React, { useState, useCallback } from "react";
import { Button } from "./button";
import { JUHUU, Offer } from "@juhuu/sdk-ts";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "./table";
import { PlusIcon, XMarkIcon, PencilIcon } from "@heroicons/react/16/solid";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "./dialog";
import { Field, Label } from "./fieldset";
import { Listbox, ListboxLabel, ListboxOption } from "./listbox";
import { TextLink } from "./text";
import LicenseTemplate from "../routes/licenseTemplates/LicenseTemplate";
import OfferTime from "../formatters/OfferTime";

interface OfferArrayPickerProps {
    setOfferArray: (offerArray: Offer[]) => void;
    offerArray: Offer[];
    tariffArray: JUHUU.Tariff.Object[];
    licenseTemplateArray: JUHUU.LicenseTemplate.Object[];
    disabled?: boolean;
    className?: string;
}

const OfferArrayPicker: React.FC<OfferArrayPickerProps> = ({
    setOfferArray,
    tariffArray,
    offerArray,
    licenseTemplateArray,
    disabled = false,
    className,
}) => {
    const [addResIsOpen, setAddResIsOpen] = useState(false);
    const [editIsOpen, setEditIsOpen] = useState(false);
    const [selectedOfferIndex, setSelectedOfferIndex] = useState<number | null>(
        null,
    );
    const [tariffId, setTariffId] = useState<string | null>(null);
    const [licenseTemplateIdArray, setLicenseTemplateIdArray] = useState<
        string[]
    >([]);

    const addResOffer = useCallback(() => {
        if (!tariffId) return;
        const newOffer: Offer = {
            tariffId,
            offerTime: {
                mon: [{ endMinutes: 1440, startMinutes: 0 }],
                tue: [{ endMinutes: 1440, startMinutes: 0 }],
                wed: [{ endMinutes: 1440, startMinutes: 0 }],
                thu: [{ endMinutes: 1440, startMinutes: 0 }],
                fri: [{ endMinutes: 1440, startMinutes: 0 }],
                sat: [{ endMinutes: 1440, startMinutes: 0 }],
                sun: [{ endMinutes: 1440, startMinutes: 0 }],
            },
            licenseTemplateIdArray: ["general"],
        };
        setOfferArray([...offerArray, newOffer]);
        setTariffId(null);
        setAddResIsOpen(false);
    }, [tariffId, offerArray, setOfferArray]);

    const handleEditOffer = useCallback(() => {
        if (selectedOfferIndex === null || !tariffId) return;
        const updatedOffer: Offer = {
            ...offerArray[selectedOfferIndex],
            tariffId,
            licenseTemplateIdArray,
        };
        const updatedOfferArray = [...offerArray];
        updatedOfferArray[selectedOfferIndex] = updatedOffer;
        setOfferArray(updatedOfferArray);
        setEditIsOpen(false);
        setSelectedOfferIndex(null);
        setTariffId(null);
        setLicenseTemplateIdArray([]);
    }, [
        selectedOfferIndex,
        tariffId,
        licenseTemplateIdArray,
        offerArray,
        setOfferArray,
    ]);

    const openEditDialog = useCallback(
        (index: number) => {
            const offer = offerArray[index];
            setTariffId(offer.tariffId);
            setLicenseTemplateIdArray(offer?.licenseTemplateIdArray ?? []);
            setSelectedOfferIndex(index);
            setEditIsOpen(true);
        },
        [offerArray],
    );

    const handleDeleteOffer = useCallback(
        (index: number) => {
            const updatedOfferArray = offerArray.filter((_, i) => i !== index);
            setOfferArray(updatedOfferArray);
        },
        [offerArray, setOfferArray],
    );

    return (
        <div className={className}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Tariff</TableHeader>
                        <TableHeader>Licenses</TableHeader>
                        <TableHeader>Opening Hours</TableHeader>
                        <TableHeader className="text-right">
                            Actions
                        </TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {offerArray.map((offer, i) => (
                        <TableRow key={i}>
                            <TableCell>
                                <TextLink
                                    href={"../../../tariffs/" + offer.tariffId}
                                >
                                    {
                                        tariffArray.find(
                                            (tariff) =>
                                                tariff.id === offer.tariffId,
                                        )?.name.en
                                    }
                                </TextLink>
                            </TableCell>
                            <TableCell>
                                {offer?.licenseTemplateIdArray?.map((id) => (
                                    <LicenseTemplate
                                        key={id}
                                        licenseTemplate={licenseTemplateArray.find(
                                            (licenseTemplate) =>
                                                licenseTemplate.id === id,
                                        )}
                                    />
                                ))}
                            </TableCell>
                            <TableCell>
                                <OfferTime offerTime={offer.offerTime} />
                            </TableCell>
                            <TableCell>
                                <div className="flex justify-center gap-4 flex-col items-end">
                                    <Button
                                        outline
                                        onClick={() => openEditDialog(i)}
                                    >
                                        <PencilIcon />
                                    </Button>
                                    <Button
                                        outline
                                        onClick={() => handleDeleteOffer(i)}
                                    >
                                        <XMarkIcon />
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className="flex justify-center w-full mt-4">
                <Button plain onClick={() => setAddResIsOpen(true)}>
                    <PlusIcon /> Add offer
                </Button>
            </div>
            <Dialog open={addResIsOpen} onClose={() => setAddResIsOpen(false)}>
                <DialogTitle>Add offer</DialogTitle>
                <DialogBody>
                    <Field>
                        <Label>Tariff</Label>
                        <Listbox
                            name="tariff"
                            value={tariffId}
                            onChange={setTariffId}
                        >
                            {tariffArray.map((tariff) => (
                                <ListboxOption
                                    key={tariff.id}
                                    value={tariff.id}
                                >
                                    <ListboxLabel>
                                        {tariff.name.en}
                                    </ListboxLabel>
                                </ListboxOption>
                            ))}
                        </Listbox>
                    </Field>
                </DialogBody>
                <DialogActions>
                    <Button plain onClick={() => setAddResIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={addResOffer} disabled={!tariffId}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={editIsOpen} onClose={() => setEditIsOpen(false)}>
                <DialogTitle>Edit Offer</DialogTitle>
                <DialogBody>
                    <Field>
                        <Label>Tariff</Label>
                        <Listbox
                            name="tariff"
                            value={tariffId}
                            onChange={setTariffId}
                        >
                            {tariffArray.map((tariff) => (
                                <ListboxOption
                                    key={tariff.id}
                                    value={tariff.id}
                                >
                                    <ListboxLabel>
                                        {tariff.name.en}
                                    </ListboxLabel>
                                </ListboxOption>
                            ))}
                        </Listbox>
                    </Field>
                </DialogBody>
                <DialogActions>
                    <Button plain onClick={() => setEditIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleEditOffer}
                        disabled={!tariffId || !licenseTemplateIdArray.length}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default OfferArrayPicker;
