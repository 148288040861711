import { useCallback, useEffect, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading, Subheading } from "../../components/heading";
import { Text } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";
import { Divider } from "../../components/divider";
import { Link, useParams } from "react-router-dom";
import { Button } from "../../components/button";
import {
    DescriptionDetails,
    DescriptionList,
    DescriptionTerm,
} from "../../components/description-list";

interface ProductRouteProps {}

const ProductRoute: React.FC<ProductRouteProps> = () => {
    const juhuu = useJUHUU();
    const [product, setProduct] = useState<JUHUU.Product.Object>();
    const { productId } = useParams<{ productId: string }>();

    const handleRefresh = useCallback(async () => {
        if (productId === undefined) {
            return;
        }

        const query = await juhuu.products.retrieve({
            productId: productId,
        });

        if (query.ok === false) {
            return;
        }

        setProduct(query.data.product);
    }, [productId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
                <div className="flex gap-4 items-center">
                    <Heading>{product?.name}</Heading>
                </div>

                <div className="flex gap-4 items-center">
                    <Text>{product?.id}</Text>
                    <Button href="./edit">Edit</Button>
                </div>
            </div>
            <Divider />
            <Subheading className="mt-10">Article</Subheading>
            <Divider />
            <DescriptionList>
                <DescriptionTerm>Article ID</DescriptionTerm>
                <DescriptionDetails>
                    <Link to={`./../../articles/${product?.articleId}`}>
                        {product?.articleId}
                    </Link>
                </DescriptionDetails>
                <DescriptionTerm>Article ID</DescriptionTerm>
                {/* <DescriptionDetails>
                    <Text>{product?.bannerImageLight}</Text>
                </DescriptionDetails> */}

                {/* {product?.description?.en !== "" &&
                    product?.description?.en && (
                        <>
                            <DescriptionTerm>Description</DescriptionTerm>
                            <DescriptionDetails>
                                <Text>{product?.description?.en}</Text>
                            </DescriptionDetails>
                        </>
                    )}

                {product?.previewText?.en !== "" &&
                    product?.previewText?.de && (
                        <>
                            <DescriptionTerm>Preview Text</DescriptionTerm>
                            <DescriptionDetails>
                                <Text>{product?.previewText.en}</Text>
                            </DescriptionDetails>
                        </>
                    )}

                {
                    
                    product?.previewText?.de && (
                        <>
                            <DescriptionTerm>Preview Text</DescriptionTerm>
                            <DescriptionDetails>
                                <Text>{product?.previewText.de}</Text>
                            </DescriptionDetails>
                        </>
                    )
                } */}
            </DescriptionList>
        </>
    );
};

export default ProductRoute;
/*
amountAuthorized: number; // amount of the article that was initially authorized
    amountAuthorizedWithoutServiceFee: number; // amount of the article that was authorized without the service fee
    amountFinal: number | null; // amount that was withdrawn from the user
    amountCaptured: number | null; // amount that was captured from the user
    amountToPayout: number | null;*/
