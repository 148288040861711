import { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";

import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text, TextLink } from "../../components/text";
import { Avatar } from "../../components/avatar";
import { Navbar, NavbarItem, NavbarSection } from "../../components/navbar";
import { Button } from "../../components/button";
import { useJUHUU } from "../../context/JuhuuContext";
import LatLongArray from "../../components/lat-long-array";

interface PointsListRouteProps {}

const PointsListRoute: React.FC<PointsListRouteProps> = () => {
    const [pointArray, setPointArray] = useState<JUHUU.Point.Single[]>([]);
    const [display, setDisplay] = useState<"table" | "map">("map");
    const juhuu = useJUHUU();

    const { property } = useProperty();
    const handleRefresh = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }
        const query = await juhuu.points.list({
            propertyId: property.id,
        });

        if (query.ok === false) {
            return;
        }

        setPointArray(
            query.data.filter(
                (point) => point.type === "single",
            ) as JUHUU.Point.Single[],
        );
    }, [property, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <Heading>Markers</Heading>
            <Text className="mb-8">
                This list contains all of your map-markers. These markers are
                displayed on the map of the JUHUU App. If a user clicks on one
                of them, they are forwarded to one of your{" "}
                <TextLink href="./../locations">locations</TextLink>.
            </Text>
            <Navbar>
                <NavbarSection className="max-lg:hidden">
                    <NavbarItem
                        current={display === "map"}
                        onClick={() => setDisplay("map")}
                    >
                        Map
                    </NavbarItem>
                    <NavbarItem
                        current={display === "table"}
                        onClick={() => setDisplay("table")}
                    >
                        Table
                    </NavbarItem>
                </NavbarSection>
            </Navbar>
            {display === "table" && (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Forwards to</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pointArray.map((point) => {
                            return (
                                <TableRow key={point.id}>
                                    {/*href={"./" + location.id}*/}
                                    <TableCell className="font-medium flex flex-row justify-start items-center gap-4">
                                        <Avatar
                                            src={point.iconLight}
                                            className="size-12"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            href={
                                                "./../locations/" +
                                                point.referenceObjectId
                                            }
                                        >
                                            Go to location
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}

            {display === "map" && (
                <LatLongArray
                    className="w-full h-96"
                    locations={pointArray.map((point) => {
                        return {
                            latitude: point.location.coordinates[1],
                            longitude: point.location.coordinates[0],
                            image: point.iconLight ?? undefined,
                        };
                    })}
                />
            )}
        </>
    );
};

export default PointsListRoute;
