import React, { createContext, useContext, useState, useCallback } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { useJUHUU } from "../context/JuhuuContext";

interface BuddyContextType {
    selectedChat: JUHUU.Chat.Object | null;
    setSelectedChat: (chat: JUHUU.Chat.Object | null) => void;
    chatMessages: JUHUU.ChatMessage.Object[];
    setChatMessages: React.Dispatch<
        React.SetStateAction<JUHUU.ChatMessage.Object[]>
    >;
    fetchMessages: (chatId: string) => Promise<void>;
}

const BuddyContext = createContext<BuddyContextType>({} as BuddyContextType);

export function useBuddy() {
    return useContext(BuddyContext);
}

interface BuddyProviderProps {
    children?: React.ReactNode;
}

const BuddyProvider: React.FC<BuddyProviderProps> = ({ children }) => {
    const juhuu = useJUHUU();
    const [selectedChat, setSelectedChat] = useState<JUHUU.Chat.Object | null>(
        null,
    );
    const [chatMessages, setChatMessages] = useState<
        JUHUU.ChatMessage.Object[]
    >([]);

    const fetchMessages = useCallback(
        async (chatId: string) => {
            const query = await juhuu.chatMessages.list(
                { chatId },
                { limit: 10 },
            );

            if (query.ok) {
                setChatMessages(query.data.chatMessageArray.reverse());
            }
        },
        [juhuu],
    );

    return (
        <BuddyContext.Provider
            value={{
                selectedChat,
                setSelectedChat,
                chatMessages,
                setChatMessages,
                fetchMessages,
            }}
        >
            {children}
        </BuddyContext.Provider>
    );
};

export default BuddyProvider;
