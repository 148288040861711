import React, { useState } from "react";
import { GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "./dialog";
import LatLongArray from "./lat-long-array";
import clsx from "clsx";
import { Button } from "./button";

type LatLongPickerProps = {
    latitude: number | null;
    longitude: number | null;
    setLatitude: (latitude: number) => void;
    setLongitude: (longitude: number) => void;
    className?: string;
};

const containerStyle = {
    height: "400px",
    width: "100%",
    borderRadius: "15px",
    overflow: "hidden",
};

const LatLongPicker: React.FC<LatLongPickerProps> = ({
    latitude,
    longitude,
    setLatitude,
    setLongitude,
    className,
}) => {
    const [mapLoaded, setMapLoaded] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleMapClick = (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
            setLatitude(event.latLng.lat());
            setLongitude(event.latLng.lng());
        }
    };

    return (
        <>
            <div className={clsx("w-full flex flex-col gap-4", className)}>
                <LatLongArray
                    className="w-full h-32"
                    locations={[
                        {
                            latitude: latitude || 0,
                            longitude: longitude || 0,
                        },
                    ]}
                />
                <Button onClick={() => setIsOpen(true)}>Select location</Button>
            </div>

            <Dialog open={isOpen} onClose={setIsOpen} size="4xl">
                <DialogTitle>Select GPS coordinates</DialogTitle>
                <DialogBody>
                    <LoadScriptNext
                        googleMapsApiKey="AIzaSyBhqDFMAGPGH-QyoTJL55RAobduqqdaPeI"
                        preventGoogleFontsLoading={true}
                        onLoad={() => setMapLoaded(true)}
                        onUnmount={() => setMapLoaded(false)}
                    >
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={{ lat: latitude || 0, lng: longitude || 0 }}
                            zoom={10}
                            onClick={handleMapClick}
                        >
                            {mapLoaded &&
                                latitude !== null &&
                                longitude !== null && (
                                    <Marker
                                        position={{
                                            lat: latitude,
                                            lng: longitude,
                                        }}
                                    />
                                )}
                        </GoogleMap>
                    </LoadScriptNext>
                </DialogBody>
                <DialogActions>
                    <Button onClick={() => setIsOpen(false)}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LatLongPicker;
