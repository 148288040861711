import React, { useCallback, useMemo, useState } from "react";
import { useProperty } from "../../context/PropertyContext";
import { useJUHUU } from "../../context/JuhuuContext";
import { Button } from "../../components/button";

interface StartSubscriptionBannerProps {}

const StartSubscriptionBanner: React.FC<StartSubscriptionBannerProps> = () => {
    const { property } = useProperty();
    const juhuu = useJUHUU();

    const [openStripeSubscriptionStartUrl, setOpenStripeSubscriptionStartUrl] =
        useState(false);

    const handleOpenStripeSubscriptionStartUrl = useCallback(async () => {
        if (property === null) {
            return;
        }

        setOpenStripeSubscriptionStartUrl(true);
        const query = await juhuu.properties.retrieveStripeSubscriptionStartUrl(
            {
                propertyId: property?.id,
            },
        );
        setOpenStripeSubscriptionStartUrl(false);

        if (query.ok === false) {
            return;
        }

        window.open(query.data.url);
    }, [property, juhuu]);

    const text: string | null = useMemo(() => {
        if (property === null) {
            return null;
        }

        switch (property.subscriptionStatus) {
            case "active": {
                return null;
            }

            case "waitingForActivationConfirmation": {
                return null;
            }

            case "inactive": {
                return "Unlock your full potential now.";
            }

            case "waitingForExpiry": {
                return "We are sorry to see you go. Please contact support so we can discuss what you disliked about JUHUU.";
            }

            default: {
                return null;
            }
        }
    }, [property]);

    if (text === null) {
        return null;
    }

    return (
        <>
            <div className="relative">
                <div className="absolute -inset-1 bg-purple-600 rounded-lg blur"></div>
                <div className="relative w-full flex flex-row mb-8 justify-between rounded-lg p-4 items-center bg-gradient-to-r from-fuchsia-600 to-purple-600">
                    <p className="text-white text-lg font-semibold">{text}</p>
                    <Button
                        color="white"
                        loading={openStripeSubscriptionStartUrl}
                        onClick={() => handleOpenStripeSubscriptionStartUrl()}
                        visible={property?.subscriptionStatus === "inactive"}
                    >
                        Start Subscription
                    </Button>
                </div>
            </div>
        </>
    );
};

export default StartSubscriptionBanner;
