import React from "react";
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownMenu,
} from "./dropdown";
import { CurrencyCode } from "@juhuu/sdk-ts";

interface CurrencyCodePickerProps {
    currencyCode: CurrencyCode | null;
    setCurrencyCode: (currencyCode: CurrencyCode) => void;
    disabled?: boolean;
}

// Mapping between currency codes and their display texts
const currencyCodeDisplayTexts = {
    eur: "Euro (EUR)",
    usd: "United States Dollar (USD)",
    gbp: "British Pound (GBP)",
    nok: "Norwegian Krone (NOK)",
    sek: "Swedish Krona (SEK)",
    chf: "Swiss Franc (CHF)",
    dkk: "Danish Krone (DKK)",
    pln: "Polish Złoty (PLN)",
    czk: "Czech Koruna (CZK)",
    huf: "Hungarian Forint (HUF)",
    bam: "Bosnia and Herzegovina Convertible Mark (BAM)",
    rsd: "Serbian Dinar (RSD)",
    isk: "Icelandic Króna (ISK)",
    cad: "Canadian Dollar (CAD)",
};

// List of supported currency codes
const supportedCurrencyCodes: CurrencyCode[] = [
    "eur",
    "usd",
    "gbp",
    "nok",
    "sek",
    "chf",
    "dkk",
    "pln",
    "czk",
    "huf",
    "bam",
    "rsd",
    "isk",
    "cad",
];

const CurrencyCodePicker: React.FC<CurrencyCodePickerProps> = ({
    currencyCode,
    setCurrencyCode,
    disabled = false,
}) => {
    // Get the display text based on the current currencyCode
    const displayText =
        currencyCode === null
            ? "Select currency"
            : currencyCodeDisplayTexts[currencyCode];

    return (
        <Dropdown>
            <DropdownButton outline disabled={disabled}>
                {displayText}
            </DropdownButton>
            <DropdownMenu>
                {supportedCurrencyCodes.map((code) => (
                    <DropdownItem
                        key={code}
                        onClick={() => {
                            setCurrencyCode(code);
                        }}
                    >
                        {currencyCodeDisplayTexts[code]}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

export default CurrencyCodePicker;
