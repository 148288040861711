import React from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Listbox, ListboxLabel, ListboxOption } from "./listbox";

interface LocationPickerProps {
    /**
     * null, if no location is selected or selected by default
     */
    location: JUHUU.Location.Object | null;
    setLocation: (location: JUHUU.Location.Object | null) => void;

    /**
     * Array of locations to choose from
     */
    locationArray: JUHUU.Location.Object[];
}

const LocationPicker: React.FC<LocationPickerProps> = ({
    location,
    setLocation,
    locationArray,
}) => {
    const handleLocationChange = (value: string) => {
        const selectedLocation =
            locationArray.find((t) => t.id === value) || null;
        setLocation(selectedLocation);
    };

    return (
        <Listbox
            value={location?.id || ""}
            onChange={handleLocationChange}
            placeholder="Select a location&hellip;"
        >
            {locationArray.map((location) => (
                <ListboxOption key={location.id} value={location.id}>
                    <ListboxLabel>{location.name}</ListboxLabel>
                </ListboxOption>
            ))}
        </Listbox>
    );
};

export default LocationPicker;
