import React from "react";
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownMenu,
} from "./dropdown";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { JUHUU } from "@juhuu/sdk-ts";

interface SessionTypePickerProps {
    sessionType: JUHUU.Session.Object["type"];
    setSessionType: (type: JUHUU.Session.Object["type"]) => void;
}

// Mapping between sessionType values and their display texts
const sessionTypeDisplayTexts = {
    rent: "Rental",
    reservation: "Reservation",
};

const SessionTypePicker: React.FC<SessionTypePickerProps> = ({
    sessionType,
    setSessionType,
}) => {
    // Get the display text based on the current sessionType
    const displayText = sessionTypeDisplayTexts[sessionType] || sessionType;

    return (
        <Dropdown>
            <DropdownButton outline>
                {displayText}
                <ChevronDownIcon />
            </DropdownButton>
            <DropdownMenu>
                <DropdownItem
                    onClick={() => {
                        setSessionType("rent");
                    }}
                >
                    Rental
                </DropdownItem>
                <DropdownItem
                    onClick={() => {
                        setSessionType("reservation");
                    }}
                >
                    Reservation
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default SessionTypePicker;
