import React, { useCallback, useState } from "react";
import { Button } from "./button";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "./dialog";
import { Category, Modality, Purpose, Sector } from "@juhuu/sdk-ts";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "./table";
import { PlusIcon, XMarkIcon, PencilIcon } from "@heroicons/react/16/solid";
import { Field, Label } from "./fieldset";
import { Listbox, ListboxLabel, ListboxOption } from "./listbox";
import { Text } from "./text";
import { Alert, AlertTitle, AlertDescription, AlertActions } from "./alert";

interface PurposeArrayPickerProps {
    setPurposeArray: (purposeArray: Purpose[]) => void;
    purposeArray: Purpose[];
    disabled?: boolean;
}

const PurposeArrayPicker: React.FC<PurposeArrayPickerProps> = ({
    setPurposeArray,
    purposeArray,
    disabled = false,
}) => {
    const [popupOpen, setPopupOpen] = useState(false);
    const [editIsOpen, setEditIsOpen] = useState(false);
    const [deleteIsOpen, setDeleteIsOpen] = useState(false);
    const [selectedPurposeIndex, setSelectedPurposeIndex] = useState<
        number | null
    >(null);

    const [sector, setSector] = useState<Sector | null>(null);
    const [category, setCategory] = useState<Category | null>(null);
    const [modality, setModality] = useState<Modality | null>(null);

    const addPurpose = useCallback(() => {
        if (sector === null || category === null || modality === null) {
            return;
        }
        setPurposeArray([...purposeArray, { sector, category, modality }]);
        resetForm();
    }, [sector, category, modality, purposeArray, setPurposeArray]);

    const editPurpose = useCallback(() => {
        if (sector === null || category === null || modality === null) {
            return;
        }

        if (selectedPurposeIndex === null) {
            return;
        }
        const updatedArray = purposeArray.map((p, i) =>
            i === selectedPurposeIndex
                ? { ...p, sector, category, modality }
                : p,
        );
        setPurposeArray(updatedArray);
        resetForm();
    }, [
        selectedPurposeIndex,
        sector,
        category,
        modality,
        purposeArray,
        setPurposeArray,
    ]);

    const deletePurpose = useCallback(() => {
        if (selectedPurposeIndex === null) return;
        setPurposeArray(
            purposeArray.filter((_, i) => i !== selectedPurposeIndex),
        );
        setDeleteIsOpen(false);
    }, [selectedPurposeIndex, purposeArray, setPurposeArray]);

    const resetForm = () => {
        setPopupOpen(false);
        setEditIsOpen(false);
        setDeleteIsOpen(false);
        setSector(null);
        setCategory(null);
        setModality(null);
        setSelectedPurposeIndex(null);
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Sector</TableHeader>
                        <TableHeader>Category</TableHeader>
                        <TableHeader>Modality</TableHeader>
                        <TableHeader className="text-right">
                            Actions
                        </TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {purposeArray.map((purpose, i) => (
                        <TableRow key={i}>
                            <TableCell>
                                <Text>{purpose.sector}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{purpose.category}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{purpose.modality}</Text>
                            </TableCell>
                            <TableCell className="flex justify-end gap-4 flex-row items-center">
                                <Button
                                    outline
                                    onClick={() => {
                                        setSelectedPurposeIndex(i);
                                        setSector(purpose.sector);
                                        setCategory(purpose.category);
                                        setModality(purpose.modality);
                                        setEditIsOpen(true);
                                    }}
                                >
                                    <PencilIcon />
                                </Button>
                                <Button
                                    outline
                                    onClick={() => {
                                        setSelectedPurposeIndex(i);
                                        setDeleteIsOpen(true);
                                    }}
                                >
                                    <XMarkIcon />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <div className="flex justify-center w-full mt-4">
                <Button
                    plain
                    className="flex items-center gap-1"
                    onClick={() => setPopupOpen(true)}
                >
                    <PlusIcon /> Add Purpose
                </Button>
            </div>

            <Dialog open={popupOpen || editIsOpen} onClose={resetForm}>
                <DialogTitle>
                    {editIsOpen ? "Edit Purpose" : "Add Purpose"}
                </DialogTitle>
                <DialogBody>
                    <Field>
                        <Label>Sector</Label>
                        <Listbox
                            name="sector"
                            value={sector}
                            onChange={setSector}
                        >
                            <ListboxOption value="tourism">
                                <ListboxLabel>Tourism</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value="mobility">
                                <ListboxLabel>Mobility</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value="sport">
                                <ListboxLabel>Sport</ListboxLabel>
                            </ListboxOption>
                        </Listbox>
                    </Field>
                    <Field>
                        <Label>Category</Label>
                        <Listbox
                            name="category"
                            value={category}
                            onChange={setCategory}
                        >
                            <ListboxOption value="bike">
                                <ListboxLabel>Bike</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value="car">
                                <ListboxLabel>Car</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value="scooter">
                                <ListboxLabel>Scooter</ListboxLabel>
                            </ListboxOption>
                        </Listbox>
                    </Field>
                    <Field>
                        <Label>Modality</Label>
                        <Listbox
                            name="modality"
                            value={modality}
                            onChange={setModality}
                        >
                            <ListboxOption value="charge">
                                <ListboxLabel>Charge</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value="store">
                                <ListboxLabel>Store</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value="share">
                                <ListboxLabel>Share</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value="wash">
                                <ListboxLabel>Wash</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value="repair">
                                <ListboxLabel>Repair</ListboxLabel>
                            </ListboxOption>
                        </Listbox>
                    </Field>
                </DialogBody>
                <DialogActions>
                    <Button plain onClick={resetForm}>
                        Cancel
                    </Button>
                    <Button
                        onClick={editIsOpen ? editPurpose : addPurpose}
                        disabled={!sector || !category || !modality}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Alert open={deleteIsOpen} onClose={resetForm}>
                <AlertTitle>Confirm Delete</AlertTitle>
                <AlertDescription>
                    Are you sure you want to delete this purpose?
                </AlertDescription>
                <AlertActions>
                    <Button plain onClick={resetForm}>
                        Cancel
                    </Button>
                    <Button onClick={deletePurpose}>Delete</Button>
                </AlertActions>
            </Alert>
        </>
    );
};

export default PurposeArrayPicker;
