import { useCallback, useEffect, useMemo, useState } from "react";
import { Heading, Subheading } from "../../components/heading";
import { Button } from "../../components/button";
import { Divider } from "../../components/divider";
import {
    Description,
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from "../../components/fieldset";
import { Input } from "../../components/input";
import { useNavigate, useParams } from "react-router-dom";
import { Select } from "../../components/select";
import { AutoRenewMode, JUHUU, Purpose } from "@juhuu/sdk-ts";

import { Switch } from "../../components/switch";
import { Strong, Text } from "../../components/text";
import Duration from "../../formatters/Duration";
import { PlusIcon, TrashIcon } from "@heroicons/react/16/solid";
import { useJUHUU } from "../../context/JuhuuContext";
import PurposeArrayPicker from "../../components/purposeArray-picker";

interface ProductEditRouteProps {}

const ProductEditRoute: React.FC<ProductEditRouteProps> = () => {
    const navigate = useNavigate();
    const [productSaveLoading, setProductSaveLoading] = useState(false);
    const { productId } = useParams<{ productId: string }>();
    const juhuu = useJUHUU();
    const [product, setProduct] = useState<JUHUU.Product.Object | null>(null);
    const [articleId, setArticleId] = useState<string | null>(null);

    const [name, setName] = useState<string>(product ? product.name : "");
    const [purposeArray, setPurposeArray] = useState<Purpose[]>([]);

    const handleUpdate = useCallback(async () => {
        if (product === null) {
            return;
        }
        setProductSaveLoading(true);

        console.log("article id" + articleId);

        const query = await juhuu.products.update({
            productId: product.id,
            name: name,
            articleId: articleId,
            purposeArray: purposeArray,
        });

        setProductSaveLoading(false);

        if (query.ok === false) {
            return;
        }

        setProduct(query.data.product);
        navigate(`./../../${product?.id}`);
    }, [product, navigate, juhuu, name, articleId, purposeArray]);

    const handleRefresh = useCallback(async () => {
        if (productId === undefined) {
            return;
        }

        const query = await juhuu.products.retrieve({
            productId: productId,
        });

        if (query.ok === false) {
            return;
        }

        setProduct(query.data.product);
        setName(query.data.product.name);
        setArticleId(query.data.product.articleId);
        setPurposeArray(query.data.product.purposeArray);
    }, [productId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <Heading>Product settings</Heading>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Product Name</Label>
                            <Description>
                                Change the name of your product
                            </Description>
                        </div>

                        <div className="w-1/2">
                            <Input
                                disabled={productSaveLoading}
                                value={name}
                                placeholder="My Product Name"
                                type="text"
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Article</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Article Id</Label>
                            <Description>
                                Change the article Id of your product
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={productSaveLoading}
                                value={articleId ?? ""}
                                placeholder="article id"
                                type="text"
                                onChange={(event) =>
                                    setArticleId(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Purposes</Subheading>
            <Divider className="mb-4" />
            <PurposeArrayPicker
                purposeArray={purposeArray}
                setPurposeArray={setPurposeArray}
            />
            <Divider className="mb-4" />
            <div className="flex justify-end gap-4">
                <Button onClick={() => navigate(-1)} plain>
                    Cancel
                </Button>
                <Button onClick={handleUpdate}>Save changes</Button>
            </div>
        </>
    );
};

export default ProductEditRoute;
