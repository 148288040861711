import { useCallback, useEffect, useState } from "react";
import { Heading, Subheading } from "../../components/heading";
import { Button } from "../../components/button";
import { Divider } from "../../components/divider";
import {
    Description,
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from "../../components/fieldset";
import { Input } from "../../components/input";
import { useNavigate, useParams } from "react-router-dom";
import { JUHUU } from "@juhuu/sdk-ts";
import { useJUHUU } from "../../context/JuhuuContext";
import { Switch } from "../../components/switch";

interface ConnectorEditRouteProps {}

const ConnectorEditRoute: React.FC<ConnectorEditRouteProps> = () => {
    const navigate = useNavigate();
    const [connectorSaveLoading, setConnectorSaveLoading] = useState(false);
    const { connectorId } = useParams<{ connectorId: string }>();
    const juhuu = useJUHUU();
    const [connector, setConnector] = useState<JUHUU.Connector.Object | null>(
        null,
    );
    const [name, setName] = useState<string>(connector ? connector.name : "");
    const [description, setDescription] = useState<string | null>(null);
    const [connectionMode, setConnectionMode] = useState<
        "alwaysOnline" | "temporaryOnline" | null
    >(null);
    const [simId, setSimId] = useState<string | null>(null);

    const handleUpdate = useCallback(async () => {
        if (connector === null) {
            return;
        }
        setConnectorSaveLoading(true);

        //TODO:

        const query = await juhuu.connectors.update({
            connectorId: connector.id,
            name: name,
            description: description,
            connectionMode:
                connectionMode === null ? "alwaysOnline" : connectionMode,
            simId: simId,
        });

        setConnectorSaveLoading(false);

        if (query.ok === false) {
            return;
        }

        setConnector(query.data.connector);
        navigate(`./../../${connector?.id}`);
    }, [connector, navigate, juhuu, name, description, connectionMode, simId]);

    const handleRefresh = useCallback(async () => {
        if (connectorId === undefined) {
            return;
        }

        const query = await juhuu.connectors.retrieve({
            connectorId: connectorId,
        });

        if (query.ok === false) {
            return;
        }

        setConnector(query.data.connector);
        setName(query.data.connector.name);
        setDescription(query.data.connector.description);
        setConnectionMode(query.data.connector.connectionMode);
        setSimId(query.data.connector.simId);
    }, [connectorId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <Heading>Connector settings</Heading>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Connector Name</Label>
                            <Description>
                                Change the name of your connector
                            </Description>
                        </div>

                        <div className="w-1/2">
                            <Input
                                disabled={connectorSaveLoading}
                                value={name}
                                placeholder="My Product Name"
                                type="text"
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Device Description</Label>
                            <Description>
                                Change the description of your product
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={connectorSaveLoading}
                                value={description ?? ""}
                                placeholder="connector description"
                                type="text"
                                onChange={(event) =>
                                    setDescription(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Status</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 my-auto">
                            <div className="inline-flex rounded-[10px]">
                                {connectionMode === "temporaryOnline" && (
                                    <>
                                        <Button
                                            plain
                                            onClick={() =>
                                                setConnectionMode(
                                                    "alwaysOnline",
                                                )
                                            }
                                        >
                                            Always Online
                                        </Button>

                                        <Button className="ml-2">
                                            Temporary Online
                                        </Button>
                                    </>
                                )}
                                {connectionMode === "alwaysOnline" && (
                                    <>
                                        <Button>Always Online</Button>
                                        <Button
                                            className="ml-2"
                                            plain
                                            onClick={() =>
                                                setConnectionMode(
                                                    "temporaryOnline",
                                                )
                                            }
                                        >
                                            Temporary Online
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="w-1/2">
                            {connectionMode === "temporaryOnline" && (
                                <>
                                    <Label>Temporary Online</Label>
                                    <Description>
                                        You will not be informed if your device
                                        is not online
                                    </Description>
                                </>
                            )}
                            {connectionMode === "alwaysOnline" && (
                                <>
                                    <Label> Always Online</Label>
                                    <Description>
                                        You will be informed if your device is
                                        not online
                                    </Description>
                                </>
                            )}
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Sim Card</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-4">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Sim Card Id</Label>
                            <Description>
                                Change the Sim Card of your Connector
                            </Description>
                        </div>

                        <div className="w-1/2">
                            <Input
                                disabled={connectorSaveLoading}
                                value={simId ?? "sim card Id"}
                                placeholder="My sim card Id"
                                type="text"
                                onChange={(event) =>
                                    setSimId(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <div className="flex justify-end gap-4">
                <Button onClick={() => navigate(-1)} plain>
                    Cancel
                </Button>
                <Button onClick={handleUpdate}>Save changes</Button>
            </div>
        </>
    );
};

export default ConnectorEditRoute;
