import React, { useState } from "react";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "./dialog";
import clsx from "clsx";
import { Button } from "./button";
import { LocaleString } from "@juhuu/sdk-ts";

type LocaleStringPickerProps = {
    value: LocaleString | null;
    setValue: (value: LocaleString) => void;
    className?: string;
};

const languages: { code: keyof LocaleString; label: string }[] = [
    { code: "en", label: "English" },
    { code: "de", label: "German" },
    { code: "fr", label: "French" },
    { code: "it", label: "Italian" },
    { code: "nl", label: "Dutch" },
    { code: "es", label: "Spanish" },
    { code: "da", label: "Danish" },
    { code: "hr", label: "Croatian" },
    { code: "hu", label: "Hungarian" },
    { code: "no", label: "Norwegian" },
    { code: "pl", label: "Polish" },
    { code: "sv", label: "Swedish" },
    { code: "cs", label: "Czech" },
    { code: "et", label: "Estonian" },
];

const LocaleStringPicker: React.FC<LocaleStringPickerProps> = ({
    value,
    setValue,
    className,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (lang: keyof LocaleString, newValue: string) => {
        setValue({ ...value, [lang]: newValue });
    };

    return (
        <>
            <div className={clsx("w-full flex flex-col gap-4", className)}>
                <div className="p-2 border rounded-md">
                    {value?.en || "(No english text)"}
                </div>
                <Button onClick={() => setIsOpen(true)}>
                    Edit Translations
                </Button>
            </div>

            <Dialog open={isOpen} onClose={() => setIsOpen(false)} size="4xl">
                <DialogTitle>Edit Translations</DialogTitle>
                <DialogBody>
                    <div className="flex flex-col gap-4">
                        {languages.map(({ code, label }) => (
                            <div key={code} className="flex flex-col">
                                <label className="font-medium">{label}</label>
                                <input
                                    type="text"
                                    className="border p-2 rounded-md"
                                    value={
                                        value === null ? "" : value[code] || ""
                                    }
                                    onChange={(e) =>
                                        handleChange(code, e.target.value)
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </DialogBody>
                <DialogActions>
                    <Button onClick={() => setIsOpen(false)}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LocaleStringPicker;
