import React from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Listbox, ListboxLabel, ListboxOption } from "./listbox";

interface TariffPickerProps {
    /**
     * null, if no tariff is selected or selected by default
     */
    tariff: JUHUU.Tariff.Object | null;
    setTariff: (tariff: JUHUU.Tariff.Object | null) => void;

    /**
     * Array of tariffs to choose from
     */
    tariffArray: JUHUU.Tariff.Object[];
}

const TariffPicker: React.FC<TariffPickerProps> = ({
    tariff,
    setTariff,
    tariffArray,
}) => {
    const handleTariffChange = (value: string) => {
        const selectedTariff = tariffArray.find((t) => t.id === value) || null;
        setTariff(selectedTariff);
    };

    return (
        <Listbox
            value={tariff?.id || ""}
            onChange={handleTariffChange}
            placeholder="Select a tariff&hellip;"
        >
            {tariffArray.map((tariff) => (
                <ListboxOption key={tariff.id} value={tariff.id}>
                    <ListboxLabel>{tariff.name.en}</ListboxLabel>
                </ListboxOption>
            ))}
        </Listbox>
    );
};

export default TariffPicker;
