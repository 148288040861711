import React, {
    useContext,
    useState,
    createContext,
    useEffect,
    useMemo,
    useCallback,
} from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Base64 } from "js-base64";
import validateAddress from "../validators/validateAddress";
import { useJUHUU } from "./JuhuuContext";
import {
    Dialog,
    DialogBody,
    DialogDescription,
    DialogTitle,
} from "../components/dialog";
import { Heading } from "../components/heading";
import { Text } from "../components/text";

type AccountSetupStatus = "processing" | "yes" | "no";

const UserContext = createContext<{
    user: JUHUU.User.Object | null;
    refreshUserContext: () => Promise<JUHUU.User.Object | null>;
    logout: () => Promise<void>;
    login: (
        accessToken: string,
        refreshToken: string,
    ) => Promise<JUHUU.User.Object | null>;
    accountSetupStatus: AccountSetupStatus;
}>({
    user: null,
    refreshUserContext: async () => null,
    logout: async () => {},
    login: async () => null,
    accountSetupStatus: "processing",
});

export function useUser() {
    return useContext(UserContext);
}

interface UserProviderProps {
    children?: React.ReactNode;
}

const UserProvider: React.FC<UserProviderProps> = ({ children = null }) => {
    const [user, setUser] = useState<JUHUU.User.Object | null>(null);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const juhuu = useJUHUU();
    const isOpen = useMemo(() => {
        if (user === null) {
            return false;
        }

        if (user.group !== "user") {
            return false;
        }

        return true;
    }, [user]);

    const accountSetupStatus: AccountSetupStatus = useMemo(() => {
        if (initialLoading === true) {
            return "processing";
        }

        if (user === null) {
            return "no";
        }

        if (!user.name || !user.billingEmail || !user.billingEmailVerified) {
            return "no";
        }

        if (user.type === "standard") {
            return "yes";
        }

        if (!user.vat || !validateAddress(user.billingAddress)) {
            return "no";
        }

        return "yes";
    }, [user, initialLoading]);

    const handleRefresh =
        useCallback(async (): Promise<JUHUU.User.Object | null> => {
            console.log("refreshing user context");
            const accessToken = localStorage.getItem("accessToken");
            const refreshToken = localStorage.getItem("refreshToken");

            if (!accessToken || !refreshToken) {
                console.log("no tokens");
                return null;
            }

            const parts = accessToken.split(".");
            if (parts.length !== 3) {
                console.warn("Invalid JWT token");
                return null;
            }

            const payload = parts[1];
            const decodedPayload = Base64.decode(payload);
            const { sub: userId } = JSON.parse(decodedPayload);

            console.log("userId", userId);

            const query = await juhuu.users.retrieve(
                { userId },
                { accessToken },
            );

            if (!query.ok) {
                return null;
            }

            setUser(query.data.user);
            return query.data.user;
        }, [juhuu]);

    useEffect(() => {
        console.log("refreshing user document");
        handleRefresh().then(() => setInitialLoading(false));
    }, [handleRefresh]);

    const handleLogout = useCallback(async (): Promise<void> => {
        console.log("logging out");
        localStorage.setItem("accessToken", "");
        localStorage.setItem("refreshToken", "");
        setUser(null);
    }, []);

    const handleLogin = useCallback(
        async (
            accessToken: string,
            refreshToken: string,
        ): Promise<JUHUU.User.Object | null> => {
            console.log("logging in");
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            return await handleRefresh();
        },
        [handleRefresh],
    );

    useEffect(() => {
        console.log("user", JSON.stringify(user, null, 2));
        console.log("initialLoading", initialLoading);
    }, [user, initialLoading]);

    const handleSelectGroup = useCallback(
        async (group: JUHUU.User.Object["group"]) => {
            if (user === null) {
                return;
            }

            const query = await juhuu.users.update({
                userId: user.id,
                group: group,
            });

            if (query.ok === false) {
                return;
            }

            setUser(query.data.user);
        },
        [user, juhuu],
    );

    return (
        <UserContext.Provider
            value={{
                user,
                refreshUserContext: handleRefresh,
                logout: handleLogout,
                login: handleLogin,
                accountSetupStatus,
            }}
        >
            {children}
            <Dialog
                open={isOpen}
                onClose={() => console.log("closed")}
                size="4xl"
            >
                <DialogTitle>Who are you?</DialogTitle>
                <DialogDescription>
                    Please select one of the three categories that best
                    describes you.
                </DialogDescription>
                <DialogBody>
                    <div className="w-full grid grid-cols-3 gap-4">
                        <div
                            className="w-full flex flex-col justify-start items-start rounded-xl p-4 bg-zinc-200 cursor-pointer"
                            onClick={() => handleSelectGroup("operator")}
                        >
                            <Heading>Operator</Heading>
                            <Text>
                                Operators manage services using the JUHUU
                                dashboard, update tariffs and support their
                                users.
                            </Text>
                        </div>
                        <div
                            className="w-full flex flex-col justify-start items-start rounded-xl p-4 bg-zinc-200 cursor-pointer"
                            onClick={() => handleSelectGroup("retailer")}
                        >
                            <Heading>Retailer</Heading>
                            <Text>
                                Retailers sell products that are compatible with
                                JUHUU to operators.
                            </Text>
                        </div>
                        <div
                            className="w-full flex flex-col justify-start items-start rounded-xl p-4 bg-zinc-200 cursor-pointer"
                            onClick={() => handleSelectGroup("engineer")}
                        >
                            <Heading>Manufacturer</Heading>
                            <Text>
                                Manufacturers integrate new products into the
                                JUHUU platform and app.
                            </Text>
                        </div>
                    </div>
                </DialogBody>
            </Dialog>
        </UserContext.Provider>
    );
};

export default UserProvider;
