import React from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import {
    Listbox,
    ListboxDescription,
    ListboxLabel,
    ListboxOption,
} from "./listbox";

interface UserPickerProps {
    /**
     * null, if no user is selected or selected by default
     */
    user: JUHUU.User.Object | null;
    setUser: (user: JUHUU.User.Object | null) => void;

    /**
     * Array of users to choose from
     */
    userArray: JUHUU.User.Object[];
}

const UserPicker: React.FC<UserPickerProps> = ({
    user,
    setUser,
    userArray,
}) => {
    const handleUserChange = (value: string) => {
        const selectedUser = userArray.find((t) => t.id === value) || null;
        setUser(selectedUser);
    };

    return (
        <Listbox
            value={user?.id || ""}
            onChange={handleUserChange}
            placeholder="Select a user&hellip;"
        >
            {userArray.map((user) => (
                <ListboxOption key={user.id} value={user.id}>
                    <ListboxLabel>{user.name}</ListboxLabel>
                    <ListboxDescription>{user.billingEmail}</ListboxDescription>
                </ListboxOption>
            ))}
        </Listbox>
    );
};

export default UserPicker;
