import { useCallback, useEffect, useState } from "react";
import { Heading, Subheading } from "../../components/heading";
import { Button } from "../../components/button";
import { Divider } from "../../components/divider";
import {
    Description,
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from "../../components/fieldset";
import { Input } from "../../components/input";
import { useNavigate, useParams } from "react-router-dom";
import { Address, Circumstance, JUHUU, Offer, Purpose } from "@juhuu/sdk-ts";
import { useJUHUU } from "../../context/JuhuuContext";
import { useProperty } from "../../context/PropertyContext";
import PurposeArrayPicker from "../../components/purposeArray-picker";
import OfferArrayPicker from "../../components/offerArray-picker";
import LocationType from "./LocationType";
import { Text } from "../../components/text";
import LatLongPicker from "../../components/lat-long-picker";
import AddressPicker from "../../components/address-picker";
import FormLayout from "../../components/formLayout-component";

interface LocationEditRouteProps {}

const LocationEditRoute: React.FC<LocationEditRouteProps> = () => {
    const navigate = useNavigate();
    const [locationSaveLoading, setLocationSaveLoading] = useState(false);
    const { locationId } = useParams<{ locationId: string }>();
    const juhuu = useJUHUU();
    const { property } = useProperty();
    const [location, setLocation] = useState<JUHUU.Location.Object | null>(
        null,
    );
    const [licenseTemplate, setLicenseTemplate] =
        useState<JUHUU.LicenseTemplate.Object | null>(null);
    const [tariffArray, setTariffArray] = useState<JUHUU.Tariff.Object[]>([]);

    // general
    const [name, setName] = useState<string>(location ? location.name : "");
    // const [address, setAdress] = useState<Address>(
    //     location?.address || {
    //         line1: "",
    //         line2: null,
    //         postalCode: "",
    //         country: "AT",
    //         city: "",
    //     },
    // );
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [surveyEnabled, setSurveyEnabled] = useState<boolean | null>(null);
    const [maximumConcurrentSessions, setMaximumConcurrentSessions] = useState<
        number | null
    >(null);
    const [deviceIdArray, setDeviceIdArray] = useState<string[]>([]);
    const [deviceId, setDeviceId] = useState<string | null>(null);
    const [accountingAreaId, setAccountingAreaId] = useState<string | null>(
        null,
    );
    const [purposeArray, setPurposeArray] = useState<Purpose[]>([]);
    const [circumstanceArray, setCircumstanceArray] = useState<Circumstance[]>(
        [],
    );
    const [rentOfferArray, setRentOfferArray] = useState<Offer[]>([]);
    const [reservationOfferArray, setReservationOfferArray] = useState<Offer[]>(
        [],
    );
    const [address, setAddress] = useState<Address | undefined>(undefined);

    const handleUpdate = useCallback(async () => {
        if (location === null) {
            return;
        }
        setLocationSaveLoading(true);

        const query = await juhuu.locations.update({
            locationId: location.id,
            name: name,
            address: address,
            deviceIdArray: deviceIdArray,
            deviceId: deviceId ?? undefined,
            maximumConcurrentSessions: maximumConcurrentSessions ?? undefined,
            surveyEnabled: surveyEnabled ?? undefined,
            accountingAreaId: accountingAreaId ?? undefined,
            latitude: latitude ?? undefined,
            longitude: longitude ?? undefined,
            purposeArray: purposeArray,
            circumstanceArray: circumstanceArray,
            rentOfferArray: rentOfferArray,
            reservationOfferArray: reservationOfferArray,
        });

        setLocationSaveLoading(false);

        if (query.ok === false) {
            return;
        }

        setLocation(query.data.location);
        navigate(`./../../${location?.id}`);
    }, [
        location,
        navigate,
        juhuu,
        name,
        address,
        surveyEnabled,
        maximumConcurrentSessions,
        deviceIdArray,
        deviceId,
        accountingAreaId,
        latitude,
        longitude,
        purposeArray,
        circumstanceArray,
        rentOfferArray,
        reservationOfferArray,
    ]);

    const handleRefresh = useCallback(async () => {
        if (locationId === undefined) {
            return;
        }

        if (property?.id === undefined) {
            return;
        }

        const queryLocation = await juhuu.locations.retrieve({
            locationId: locationId,
        });

        if (queryLocation.ok === false) {
            return;
        }

        setLocation(queryLocation.data.location);

        const queryTariffArray = await juhuu.tariffs.list({
            propertyId: property?.id,
        });

        if (queryTariffArray.ok === false) {
            return;
        }

        setTariffArray(queryTariffArray.data);

        const queryLicenseTemplate = await juhuu.licenseTemplates.retrieve({
            licenseTemplateId: "general",
        });

        if (queryLicenseTemplate.ok === false) {
            return;
        }

        setLicenseTemplate(queryLicenseTemplate.data.licenseTemplate);

        setName(queryLocation.data.location.name);
        setAddress(queryLocation.data.location.address ?? undefined);

        if (
            queryLocation.data.location.type === "rentableDevice" ||
            queryLocation.data.location.type === "rentableDeviceGroup"
        ) {
            setSurveyEnabled(queryLocation.data.location.surveyEnabled);
            setMaximumConcurrentSessions(
                queryLocation.data.location.maximumConcurrentSessions,
            );
            setAccountingAreaId(queryLocation.data.location.accountingAreaId);
        }

        if (queryLocation.data.location.type === "rentableDevice") {
            setDeviceId(queryLocation.data.location.deviceId);
        }

        if (queryLocation.data.location.type === "rentableDeviceGroup") {
            setDeviceIdArray(queryLocation.data.location.deviceIdArray);
        }

        setPurposeArray(queryLocation.data.location.purposeArray);
        setCircumstanceArray(queryLocation.data.location.circumstanceArray);
        setRentOfferArray(queryLocation.data.location.rentOfferArray);
        setReservationOfferArray(
            queryLocation.data.location.reservationOfferArray,
        );
        setLatitude(
            queryLocation.data.location.location?.coordinates[1] ?? null,
        );
        setLongitude(
            queryLocation.data.location.location?.coordinates[0] ?? null,
        );
    }, [locationId, juhuu, property]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
                <div className="flex gap-4 items-center">
                    <Heading>Edit {location?.name}</Heading>
                    <Text>
                        <LocationType type={location?.type} />
                    </Text>
                </div>

                <div className="flex gap-4 items-center">
                    <Button onClick={handleUpdate}>Save changes</Button>
                </div>
            </div>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Name</Label>
                            <Description>
                                Change the name of your location.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={locationSaveLoading}
                                value={name}
                                placeholder="Main Station"
                                type="text"
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <AddressPicker setAddress={setAddress} address={address} />
            <Subheading>Purposes</Subheading>
            <Divider className="mb-4" />
            <PurposeArrayPicker
                purposeArray={purposeArray}
                setPurposeArray={setPurposeArray}
            />
            <Subheading>Reservation Offers</Subheading>
            <Divider className="mb-4" />
            <OfferArrayPicker
                setOfferArray={setReservationOfferArray}
                offerArray={reservationOfferArray}
                tariffArray={tariffArray}
                licenseTemplateArray={
                    licenseTemplate === null ? [] : [licenseTemplate]
                }
            />
            <Subheading>Rental Offers</Subheading>
            <Divider className="mb-4" />
            <OfferArrayPicker
                className="mb-12"
                setOfferArray={setRentOfferArray}
                offerArray={rentOfferArray}
                tariffArray={tariffArray}
                licenseTemplateArray={
                    licenseTemplate === null ? [] : [licenseTemplate]
                }
            />

            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>GPS-Location</Label>
                            <Description>
                                Set the GPS coordinates of your location.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <LatLongPicker
                                latitude={latitude}
                                longitude={longitude}
                                setLatitude={setLatitude}
                                setLongitude={setLongitude}
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <FormLayout
                label="GPS-Location"
                description="Set the GPS coordinates of your location."
            >
                <LatLongPicker
                    latitude={latitude}
                    longitude={longitude}
                    setLatitude={setLatitude}
                    setLongitude={setLongitude}
                />
            </FormLayout>
            {(location?.type === "rentableDevice" ||
                location?.type === "rentableDeviceGroup") && (
                <Fieldset className="mb-12">
                    <FieldGroup>
                        <Field className="flex flex-row justify-between gap-4">
                            <div className="w-1/2 ">
                                <Label>Maximum concurrent sessions</Label>
                                <Description>
                                    Set the number of concurrent active
                                    sessions. E.g. 5 means that five users can
                                    have an active session at the same time.
                                </Description>
                            </div>
                            <div className="w-1/2">
                                <Input
                                    disabled={locationSaveLoading}
                                    value={maximumConcurrentSessions ?? ""}
                                    type="number"
                                    onChange={(event) =>
                                        setMaximumConcurrentSessions(
                                            parseInt(event.target.value),
                                        )
                                    }
                                />
                            </div>
                        </Field>
                        <Field className="flex flex-row justify-between gap-4">
                            <div className="w-1/2 ">
                                <Label>Accounting area ID</Label>
                                <Description>
                                    Set the ID of the accounting area that that
                                    should be used for rentals and reservations
                                    created at this location.
                                </Description>
                            </div>
                            <div className="w-1/2">
                                <Input
                                    disabled={locationSaveLoading}
                                    value={accountingAreaId ?? ""}
                                    type="text"
                                    onChange={(event) =>
                                        setAccountingAreaId(event.target.value)
                                    }
                                />
                            </div>
                        </Field>
                    </FieldGroup>
                </Fieldset>
            )}

            {location?.type === "rentableDevice" && (
                <Fieldset className="mb-12">
                    <FieldGroup>
                        <Field className="flex flex-row justify-between gap-4">
                            <div className="w-1/2 ">
                                <Label>Device ID</Label>
                                <Description>
                                    Set the ID of the device that a user should
                                    be able to control after they bought one of
                                    your specified offers.
                                </Description>
                            </div>
                            <div className="w-1/2">
                                <Input
                                    disabled={locationSaveLoading}
                                    value={deviceId ?? ""}
                                    type="text"
                                    onChange={(event) =>
                                        setDeviceId(event.target.value)
                                    }
                                />
                            </div>
                        </Field>
                    </FieldGroup>
                </Fieldset>
            )}

            {location?.type === "rentableDeviceGroup" && (
                <>{/* Todo: device ID array setzen */}</>
            )}
        </>
    );
};

export default LocationEditRoute;
