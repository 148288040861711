import { useCallback, useEffect, useState } from "react";
import { Heading, Subheading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Button } from "../../components/button";
import { Divider } from "../../components/divider";
import {
    Description,
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from "../../components/fieldset";
import { Input } from "../../components/input";
import { useNavigate } from "react-router-dom";
import { Address, CurrencyCode } from "@juhuu/sdk-ts";
import { useJUHUU } from "../../context/JuhuuContext";
import { Strong, Text, TextLink } from "../../components/text";
import { Badge } from "../../components/badge";
import CurrencyCodePicker from "../../components/currencyCode-picker";

interface PropertyBillingRouteProps {}

const PropertyBillingRoute: React.FC<PropertyBillingRouteProps> = () => {
    const navigate = useNavigate();
    const { property, setProperty } = useProperty();
    const [openStripeConnectPortalLoading, setOpenStripeConnectPortalLoading] =
        useState(false);
    const [
        openStripeCustomerPortalLoading,
        setOpenStripeCustomerPortalLoading,
    ] = useState(false);
    const [propertySaveLoading, setPropertySaveLoading] = useState(false);
    const juhuu = useJUHUU();

    // legalName
    const [legalName, setLegalName] = useState<string>("");

    //email
    const [stripeEmail, setStripeEmail] = useState<string>("");

    // billingAddress
    const [billingAddress, setBillingAddress] = useState<Address | null>(null);

    // invoiceNumberPrefix
    const [invoiceNumberPrefix, setInvoiceNumberPrefix] = useState<string>("");

    // payoutCurrencyCode
    const [payoutCurrencyCode, setPayoutCurrencyCode] =
        useState<CurrencyCode | null>(null);

    const handleUpdate = useCallback(async () => {
        if (property === null) {
            return;
        }

        setPropertySaveLoading(true);
        const query = await juhuu.properties.update({
            propertyId: property.id,
            legalName: legalName === "" ? undefined : legalName,
            stripeEmail: stripeEmail === "" ? undefined : stripeEmail,
            billingAddress: billingAddress,
            invoiceNumberPrefix:
                invoiceNumberPrefix === "" ? undefined : invoiceNumberPrefix,
            payoutCurrencyCode: payoutCurrencyCode ?? undefined,
        });

        setPropertySaveLoading(false);

        if (query.ok === false) {
            return;
        }

        if (query.data.property.type !== "internal") {
            return;
        }

        setProperty(query.data.property);
    }, [
        property,
        setProperty,
        legalName,
        juhuu,
        stripeEmail,
        billingAddress,
        invoiceNumberPrefix,
        payoutCurrencyCode,
    ]);

    const handleOpenStripeConnectedAccount = useCallback(async () => {
        if (property === null) {
            return;
        }

        setOpenStripeConnectPortalLoading(true);
        const query = await juhuu.properties.retrieveStripeConnectPortalUrl({
            propertyId: property?.id,
        });
        setOpenStripeConnectPortalLoading(false);

        if (query.ok === false) {
            return;
        }

        window.open(query.data.url, "_blank");
    }, [property, juhuu]);

    const handleOpenStripeCustomerPortal = useCallback(async () => {
        if (property === null) {
            return;
        }

        setOpenStripeCustomerPortalLoading(true);
        const query = await juhuu.properties.retrieveStripeCustomerPortalUrl({
            propertyId: property?.id,
        });
        setOpenStripeCustomerPortalLoading(false);

        if (query.ok === false) {
            return;
        }

        window.open(query.data.url, "_blank");
    }, [property, juhuu]);

    useEffect(() => {
        if (property === null) {
            return;
        }

        setLegalName(property.legalName ?? "");
        setStripeEmail(property.stripeEmail ?? "");
        setBillingAddress(property.billingAddress);
        setInvoiceNumberPrefix(property.invoiceNumberPrefix);
        setPayoutCurrencyCode(property.payoutCurrencyCode);
    }, [property]);

    return (
        <>
            <Heading>Billing</Heading>
            <Divider className="mb-4" />
            <Text className="mb-8">
                JUHUU processes three different kinds of transactions:
                <ol className="list-decimal">
                    <li>
                        <Strong>Subscription:</Strong> Your company's
                        subscription to JUHUU. The payment details for the
                        subscription is managed below.
                    </li>
                    <li>
                        <Strong>Payout:</Strong> JUHUU's payout to your
                        company.The payment details for the payout is managed
                        below.
                    </li>
                    <li>
                        <Strong>Payment:</Strong> The{" "}
                        <TextLink href={"../payments"}>
                            payments of your users
                        </TextLink>{" "}
                        that are subsequently payed out to you.
                    </li>
                </ol>
            </Text>
            <Fieldset className="mb-8">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Legal name</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                The name of your company we use on the invoices
                                we create on your behalf.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                defaultValue={property?.legalName ?? ""}
                                disabled={propertySaveLoading}
                                placeholder="JUHUU Mobility Ltd."
                                onChange={(event) =>
                                    setLegalName(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Subscription</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-8">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Subscription email</Label>
                            <Badge className="ml-2">Private</Badge>
                            <Description>
                                This email will be used to log you into the
                                customer portal where you can download the
                                invoices and the pament methods of your company.
                                We will also use it email to email you about
                                unpaid invoices.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="stripeEmail"
                                defaultValue={property?.stripeEmail as string}
                                disabled={propertySaveLoading}
                                placeholder="office@juhuu.app"
                                onChange={(event) =>
                                    setStripeEmail(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Payment method for subscription</Label>
                            <Description>
                                JUHUU withdraws an amount from your bank account
                                on a monthly basis based on your usage of the
                                JUHUU Platform. For that reason, you need to
                                setup a payment method from which we can
                                withdraw the amount. JUHUU partners with Stripe
                                to process the the subscription. You can manage
                                your subscription details and invoices in the
                                Stripe customer portal.
                            </Description>
                        </div>
                        <div className="w-1/2 flex justify-end items-center">
                            <div>
                                <Button
                                    onClick={handleOpenStripeCustomerPortal}
                                    loading={
                                        openStripeCustomerPortalLoading === true
                                    }
                                    disabled={property?.stripeEmail === null}
                                    disabledTooltip={
                                        "Please provide an email address that we use for the subscription process."
                                    }
                                >
                                    Open subscription customer portal
                                </Button>
                            </div>
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Payouts</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-8">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Currency</Label>
                            <Description>
                                Select the currency in which you want to receive
                                the payouts from JUHUU.
                            </Description>
                        </div>
                        <div className="w-1/2 flex justify-end">
                            <CurrencyCodePicker
                                currencyCode={payoutCurrencyCode}
                                setCurrencyCode={setPayoutCurrencyCode}
                                disabled={payoutCurrencyCode !== null}
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Customer portal for payouts</Label>
                            <Description>
                                The revenue you generate through the JUHUU
                                Platform will be payed out to you at the end of
                                each month. JUHUU partners with Stripe to
                                process the payouts. You can manage your payout
                                details in the Stripe customer portal.
                            </Description>
                        </div>
                        <div className="w-1/2 flex justify-end items-center">
                            <div>
                                <Button
                                    onClick={handleOpenStripeConnectedAccount}
                                    loading={
                                        openStripeConnectPortalLoading === true
                                    }
                                >
                                    Open payout customer portal
                                </Button>
                            </div>
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Payments</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-8">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Invoice number prefix</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                The prefix that is assigned to your invoices. If
                                the invoice number prefix is set to "WWW", the
                                invoice number will look like "WWW000006".
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                defaultValue={
                                    property?.invoiceNumberPrefix ?? ""
                                }
                                disabled={propertySaveLoading}
                                placeholder="EXT"
                                onChange={(event) =>
                                    setInvoiceNumberPrefix(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Divider className="mb-4" />
            <div className="flex justify-end gap-4">
                <Button onClick={() => navigate(-1)} plain>
                    Cancel
                </Button>
                <Button onClick={handleUpdate}>Save changes</Button>
            </div>
        </>
    );
};

export default PropertyBillingRoute;
