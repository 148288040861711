import React, { useState } from "react";
import {
    GoogleMap,
    LoadScriptNext,
    Marker,
    OverlayView,
} from "@react-google-maps/api";
import clsx from "clsx";

type LatLongArrayProps = {
    locations: { latitude: number; longitude: number; image?: string }[];
    className?: string;
};

const containerStyle = {
    height: "100%",
    width: "100%",
    borderRadius: "15px",
    overflow: "hidden",
};

const getPixelPositionOffset = (width: number, height: number) => {
    return { x: -(width / 2), y: -(height / 2) };
};

const LatLongArray: React.FC<LatLongArrayProps> = ({
    locations,
    className,
}) => {
    const [mapLoaded, setMapLoaded] = useState(false);

    const averageLat =
        locations.reduce((sum, loc) => sum + loc.latitude, 0) /
        locations.length;
    const averageLng =
        locations.reduce((sum, loc) => sum + loc.longitude, 0) /
        locations.length;

    return (
        <div className={clsx("w-full h-44", className)}>
            <LoadScriptNext
                googleMapsApiKey="AIzaSyBhqDFMAGPGH-QyoTJL55RAobduqqdaPeI"
                preventGoogleFontsLoading={true}
                onLoad={() => setMapLoaded(true)}
                onUnmount={() => setMapLoaded(false)}
            >
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{ lat: averageLat, lng: averageLng }}
                    zoom={10}
                >
                    {mapLoaded &&
                        locations.map((point, index) => (
                            <>
                                {point.image === undefined ? (
                                    <Marker
                                        position={{
                                            lat: point.latitude,
                                            lng: point.longitude,
                                        }}
                                    />
                                ) : (
                                    <OverlayView
                                        key={index}
                                        position={{
                                            lat: point.latitude,
                                            lng: point.longitude,
                                        }}
                                        mapPaneName={
                                            OverlayView.OVERLAY_MOUSE_TARGET
                                        }
                                        getPixelPositionOffset={
                                            getPixelPositionOffset
                                        }
                                    >
                                        <div
                                            style={{
                                                width: "50px",
                                                height: "50px",
                                                transform:
                                                    "translate(-50%, -50%)",
                                                background: "white",
                                                padding: "5px",
                                                borderRadius: "50%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                boxShadow:
                                                    "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                            }}
                                        >
                                            <img
                                                src={point.image}
                                                alt="marker-icon"
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                }}
                                            />
                                        </div>
                                    </OverlayView>
                                )}
                            </>
                        ))}
                </GoogleMap>
            </LoadScriptNext>
        </div>
    );
};

export default LatLongArray;
